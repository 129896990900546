import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { EditOutlined, FileCopyOutlined, VisibilityOutlined } from "@material-ui/icons";
import { IconButton, TablePagination, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import RadarConfig from "../types";
import { Order } from "../../../../Base/Table/types";
import { useTableStyles } from "../../../../Base/Table/styles";
import { descendingComparator, stableSort } from "../../../../Base/Table/helpers";
import RadarTableHead from "./Head";
import { RadarTableToolbar } from "./Toolbar";
import CopyCodeButton from "../../../../Base/IconButton/CopyCodeButton";
import StyledDialog from "../../../../Base/Dialog";
import RadarForm from "../RadarForm";
import { BASE_URL } from "../../../../../index";

export interface R extends RadarConfig {
  id: string;
}

interface RadarTableProps {
  radars: R[];
}

function getComparator<Key extends keyof R>(order: Order, orderBy: Key): (a: R, b: R) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function RadarTable({ radars }: RadarTableProps) {
  const classes = useTableStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof R>("id");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [onEdit, setOnEdit] = React.useState<RadarConfig | undefined>(undefined);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchKeys, setSearchKeys] = React.useState<string[]>([]);

  const filterSearchKey = (item: RadarConfig) =>
    // searchKeys.some((k) => item.ownerEmail.includes(k) || item.id.includes(k)) ||
    searchKeys.every(
      (k) =>
        item.title.toLowerCase().includes(k.toLowerCase()) || item.description.toLowerCase().includes(k.toLowerCase())
    );

  const filteredRows = radars.filter(filterSearchKey);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof RadarConfig | "id") => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filteredRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelectOne = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleEdit = (id: string) => {
    const { ...c } = radars.find((a) => a.id === id);
    setOnEdit(c);
    setEditDialogOpen(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, radars.length - page * rowsPerPage);

  const getCodeSnipped = (id: string, width: number, height: number) =>
    `<iframe name="KI.ASSIST: Technologie-Radar" height="${width}px" width="${height}px" scrolling="no" src="${BASE_URL}/public/radar/${id}" margin="0" frameborder="0" id="ki-assist-radar" allowfullscreen></iframe>`;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <RadarTableToolbar selected={selected} searchKeys={searchKeys} setSearchKeys={setSearchKeys} />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
            <RadarTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
            />
            <TableBody>
              {stableSort(filteredRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event: React.MouseEvent<unknown, MouseEvent>) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event: React.MouseEvent<unknown, MouseEvent>) => handleSelectOne(event, row.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell align="right" width={120}>
                        <Typography
                          variant="subtitle1"
                          style={{ width: 120, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                        >
                          {row.id}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">{row.title}</Typography>
                        <Typography variant="subtitle2">{row.description}</Typography>
                      </TableCell>
                      <TableCell width={180}>
                        <Tooltip title="Sehen">
                          <IconButton component={Link} to={`public/radar/${row.id}`} target="_blank">
                            <VisibilityOutlined />
                          </IconButton>
                        </Tooltip>
                        <CopyCodeButton
                          icon={<FileCopyOutlined />}
                          title="Einbettungscode kopieren"
                          toCopy={getCodeSnipped(row.id, row.width, row.height)}
                        />
                        <Tooltip title="Bearbeiten">
                          <IconButton onClick={() => handleEdit(row.id)}>
                            <EditOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <StyledDialog
          maxWidth="xl"
          title="Radar bearbeiten"
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
        >
          <RadarForm radarConfig={onEdit} />
        </StyledDialog>
      </Paper>
    </div>
  );
}
