import React, { useEffect, useState } from "react";
import { Collapse, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import { Add } from "@material-ui/icons";
import StyledIconButton from "../../Base/IconButton";
import Base from "../../Base";
import StyledPaper from "../../Base/Paper";
import Title from "../Dashboard/Title";
import getUsers from "./helpers";
import Roles, { Role } from "./Roles";
import StyledDialog from "../../Base/Dialog";
import EditMember from "./EditMember";
import useWarning from "../../Base/Dialog/Warning";
import CreateMember from "./CreateMember";

export type TeamMember = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
};

const useTableStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2)
  },
  container: {
    // [theme.breakpoints.up("lg")]: {
    //   minHeight: "calc(100vh - 150px)"
    // },
    "& .MuiTableBody-root .MuiTableRow-root:nth-child(4n - 3),  .MuiTableBody-root .MuiTableRow-root:nth-child(4n - 2)": {
      backgroundColor: theme.palette.action.hover
    }
  },
  tableHead: {
    "& .MuiTableCell-head": {
      color: theme.palette.primary.main,
      padding: theme.spacing(1, 2)
    }
  },
  dialogTitle: {
    color: theme.palette.primary.main
  }
}));

export default function Team(): JSX.Element {
  const classes = useTableStyles();
  const [users, setUsers] = useState<Array<TeamMember>>([]);

  const [newUserOpen, setNewUserOpen] = useState(false);

  useEffect(() => {
    getUsers().then((r) => {
      if (typeof r === "string") {
        console.warn(r);
      } else {
        setUsers(r);
      }
    });
  }, []);

  return (
    <Base title="Team">
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper className={classes.container}>
            <div className={classes.header}>
              <Title>Mitglieder</Title>
              <StyledIconButton
                title="Neuer Teammitglied hinzufügen"
                variant="dark"
                onClick={() => setNewUserOpen(!newUserOpen)}
                icon={<Add />}
              />
            </div>
            <StyledDialog title="Neuer Teammitglied erstellen" open={newUserOpen} onClose={() => setNewUserOpen(false)}>
              <CreateMember />
            </StyledDialog>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell align="center" width={100}>
                    <LockOutlinedIcon color="disabled" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  users.map((user) => <UserTableRow key={user.id} row={user} users={users} setUsers={setUsers} />)}
              </TableBody>
            </Table>
            {!users && (
              <Typography variant="subtitle2" align="center" color="error" style={{ padding: 24 }}>
                Fehler: Keine Benutzer gefunden.
              </Typography>
            )}
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Roles />
          </StyledPaper>
        </Grid>
      </Grid>
    </Base>
  );
}

export const useRowStyles = makeStyles((theme) => ({
  userRow: {
    "& .MuiTableCell-body": {
      transition: theme.transitions.create("color")
    },
    "& > *": {
      borderBottom: "unset"
    }
  },
  editing: {
    "& .MuiTableCell-body": {
      color: theme.palette.grey[400]
    }
  }
}));

interface RowProps {
  row: TeamMember;
  // TODO find a better solution (store?)
  setUsers: (users: TeamMember[]) => void;
  users: Array<TeamMember>;
}

function UserTableRow({ row, users, setUsers }: RowProps): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const warning = useWarning({
    message: `Möchtest Du diese Role wirklich löschen? Du wirst sie nicht mehr abrufen können.`,
    callback: () => {
      deleteUser();
    }
  });

  const deleteUser = async () => {
    await axios.delete(`users/${row.id}`);
    setUsers(users.filter((u: TeamMember) => u.id !== row.id));
  };

  const editBtn = (
    <Tooltip title={`${row.firstName} ${row.lastName} bearbeiten`}>
      <IconButton size="small" onClick={() => setOpen(!open)}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
  const cancelBtn = (
    <Tooltip title="Abbrechen">
      <IconButton size="small" onClick={() => setOpen(!open)}>
        <CancelIcon />
      </IconButton>
    </Tooltip>
  );
  const deleteBtn = (
    <Tooltip title={`${row.firstName} ${row.lastName} löschen`}>
      <IconButton size="small" onClick={() => warning.show()}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <TableRow className={clsx(classes.userRow, open && classes.editing)}>
        <TableCell>
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.role.name}</TableCell>
        <TableCell align="right">
          {open ? cancelBtn : editBtn}
          {deleteBtn}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <EditMember id={row.id} setOpen={setOpen} />
          </Collapse>
        </TableCell>
      </TableRow>
      <warning.Dialog />
    </>
  );
}
