import React, { useEffect, useState } from "react";
import getRadars from "./helpers";
import Base from "../../../Base";
import RadarTable, { R } from "./Table";

export default function TechnologyRadar(): JSX.Element {
  const [radars, setRadars] = useState<R[] | undefined>(undefined);

  useEffect(() => {
    getRadars().then((r) => {
      if (typeof r === "string") {
        console.warn(r);
      } else {
        setRadars(r as R[]);
      }
    });
  }, []);

  return (
    <Base title="Radar">
      <RadarTable radars={radars || []} />
    </Base>
  );
}
