import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Button, Fade, Grid, Paper } from "@material-ui/core";
import { Colorize, FormatColorResetOutlined } from "@material-ui/icons";
import axios from "axios";
import { useHistory } from "react-router-dom";
import StyledTextInput from "../../../Base/Inputs/TextInput";
import {
  FullRowGrid,
  GridRowBreak,
  HalfRowGrid,
  InputGrid,
  MainGridContainer,
  OneForthGrid,
  OneThirdGrid,
  SubTitleGrid,
  TwoThirdsGrid
} from "../../../Base/Grid";
import StyledNumberField from "../../../Base/Inputs/NumbertInput";
import StyledSwitch from "../../../Base/Inputs/Switch";
import StyledSelect from "../../../Base/Inputs/SelectInput";
import ColorInput from "../../../Base/Inputs/ColorInput";
import StyledIconButton from "../../../Base/IconButton";
import RadarConfig, {
  defaultColors,
  easingOptions,
  initialRadarValues,
  radarValidationSchema,
  resetColors
} from "./types";
import { createOption } from "../../Team/helpers";
import { handleDjangoApiError, toSnakeCase } from "../../helpers";
import useAlert from "../../../Base/Dialog/Alert";
import RadarChart from "./RadarChart";

interface TechnologyRadarFormProps {
  radarConfig?: RadarConfig;
}

export default function TechnologyRadarForm({ radarConfig }: TechnologyRadarFormProps): JSX.Element {
  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const successAlert = useAlert({ message: "success!", severity: "success" });
  const errorAlert = useAlert({ message: apiError || "", severity: "error" });

  const history = useHistory();

  return (
    <div>
      <Formik<RadarConfig>
        initialValues={radarConfig || initialRadarValues}
        validationSchema={radarValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            if (radarConfig) {
              // TODO fix types
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              await axios.put(`charts/radar/${radarConfig.id}`, toSnakeCase(values));
              successAlert.show();
            } else {
              await axios.post("charts/radar", toSnakeCase(values));
              successAlert.show();
            }
            history.go(0);
          } catch (e) {
            setApiError(handleDjangoApiError(e));
          }
        }}
      >
        {({ values, setValues }) => (
          <Form>
            <MainGridContainer style={{ position: "relative" }}>
              <Grid item container xs={12} lg={radarConfig ? 6 : 12}>
                {/* <Grid item container xs={12} lg={12}> */}
                <Grid container spacing={3}>
                  <TwoThirdsGrid container>
                    <Grid container spacing={3}>
                      <FullRowGrid>
                        <StyledTextInput name="title" label="Titel" />
                      </FullRowGrid>
                      <FullRowGrid>
                        <StyledTextInput name="description" label="Beschreibung" multiline />
                      </FullRowGrid>
                    </Grid>
                  </TwoThirdsGrid>
                  <OneThirdGrid style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                    <StyledIconButton
                      icon={<Colorize />}
                      onClick={() => setValues({ ...values, ...defaultColors })}
                      title="Standardfarben verwenden"
                      variant="dark"
                    />
                    <StyledIconButton
                      icon={<FormatColorResetOutlined />}
                      onClick={() => setValues({ ...values, ...resetColors })}
                      title="Farben zurücksetzen"
                      variant="dark"
                    />
                  </OneThirdGrid>

                  <SubTitleGrid subtitle="Radar" />
                  <InputGrid>
                    <ColorInput name="backgroundColor" label="Hintergrund" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="highlightColor" label="Highlight" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="radarLineColor" label="Radarlinien" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="radarBackgroundColor" label="Radar-Hintergrund" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="radarInnerTextColor" label="Innerer Text" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="radarOuterTextColor" label="Äußerer Text" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="radarUnselectedItemsColor" label="Punkte" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="radarFocusItemColor" label="Punkte bei Fokus" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="radarItemsTextColor" label="Punkte Text" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="radarTooltipBackgroundColor" label="Tooltip" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="radarTooltipTextColor" label="Tooltip-Text" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="linkColor" label="Link" />
                  </InputGrid>
                  <InputGrid>
                    <StyledSwitch name="radarShowTooltipShadow" label="Tooltip-Schatten" left="Ohne" right="Mit" />
                  </InputGrid>
                  <Fade in={values.radarShowTooltipShadow} mountOnEnter unmountOnExit>
                    <Grid item container xs={12} md={8}>
                      <Grid container spacing={3}>
                        <OneForthGrid>
                          <ColorInput name="radarTooltipShadowColor" label="Farbe" />
                        </OneForthGrid>
                        <OneForthGrid>
                          <StyledNumberField name="radarTooltipShadowBlur" label="Unschärfe" unit="px" />
                        </OneForthGrid>
                        <OneForthGrid>
                          <StyledNumberField name="radarTooltipShadowOffsetX" label="Versatz X" unit="px" />
                        </OneForthGrid>
                        <OneForthGrid>
                          <StyledNumberField name="radarTooltipShadowOffsetY" label="Versatz Y" unit="px" />
                        </OneForthGrid>
                      </Grid>
                    </Grid>
                  </Fade>

                  <SubTitleGrid subtitle="Gruppen" />
                  <OneThirdGrid>
                    <ColorInput name="gruppe1" label="Wahrnehmungs- und Kommunikationsunterstützung" />
                  </OneThirdGrid>
                  <OneThirdGrid>
                    <ColorInput name="gruppe2" label="Interaktionsunterstützung" />
                  </OneThirdGrid>
                  <OneThirdGrid>
                    <ColorInput name="gruppe3" label="Navigations- /Orientierungsunterstützung" />
                  </OneThirdGrid>
                  <OneThirdGrid>
                    <ColorInput name="gruppe4" label="Konkrete Arbeitsunterstützung" />
                  </OneThirdGrid>
                  <OneThirdGrid>
                    <ColorInput name="gruppe5" label="Lernunterstützung" />
                  </OneThirdGrid>
                  <OneThirdGrid>
                    <ColorInput name="gruppe6" label="Physische Unterstützung" />
                  </OneThirdGrid>
                  <OneThirdGrid>
                    <ColorInput name="gruppe7" label="Psychische Unterstützung" />
                  </OneThirdGrid>
                  <GridRowBreak />

                  <SubTitleGrid subtitle="Boxen" />
                  <InputGrid>
                    <StyledSwitch name="showViewsBox" label="Ansichten-Box anzeigen" left="Nein" right="Ja" />
                  </InputGrid>
                  <Fade in={values.showViewsBox} mountOnEnter unmountOnExit>
                    <Grid item container xs={12} md={8}>
                      <Grid container spacing={3}>
                        <OneForthGrid>
                          <ColorInput name="viewsboxBackgroundColor" label="Hintergrung" />
                        </OneForthGrid>
                        <OneForthGrid>
                          <ColorInput name="viewsboxBackgroundColorHover" label="Hinterg. bei Fokus" />
                        </OneForthGrid>
                        <OneForthGrid>
                          <ColorInput name="descriptionTextColor" label="Beschreibungstext" />
                        </OneForthGrid>
                        <OneForthGrid>
                          <ColorInput name="viewsboxBorderColor" label="Rand" />
                        </OneForthGrid>
                        <OneForthGrid>
                          <ColorInput name="viewsboxTextColor" label="Text" />
                        </OneForthGrid>
                      </Grid>
                    </Grid>
                  </Fade>

                  <SubTitleGrid subtitle="Legende" />
                  <InputGrid>
                    <StyledSwitch name="showLegendBox" label="Legendenbox anzeigen" left="Nein" right="Ja" />
                  </InputGrid>
                  <Fade in={values.showLegendBox} mountOnEnter unmountOnExit>
                    <Grid item container xs={12} md={8}>
                      <Grid container spacing={3}>
                        <OneForthGrid>
                          <ColorInput name="legendBackgroundColor" label="Hintergrund" />
                        </OneForthGrid>
                        <OneForthGrid>
                          <ColorInput name="legendBorderColor" label="Rand" />
                        </OneForthGrid>
                        <OneForthGrid>
                          <ColorInput name="legendTextColor" label="Text" />
                        </OneForthGrid>
                      </Grid>
                    </Grid>
                  </Fade>

                  <SubTitleGrid subtitle="Suche" />
                  <InputGrid>
                    <ColorInput name="searchboxBackgroundColor" label="Hintergrund" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="searchboxBorderColor" label="Rand" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="searchboxTextColor" label="Text" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="searchboxIconColor" label="Icon" />
                  </InputGrid>

                  <SubTitleGrid subtitle="Marginalspalte" />
                  <InputGrid>
                    <ColorInput name="sideboxBackgroundColor" label="Hintergrund" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="sideboxBackgroundColorHover" label="Hint. bei Fokus" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="sideboxH1Color" label="Titel" />
                  </InputGrid>
                  <InputGrid>
                    <ColorInput name="sideboxH2Color" label="Untertitel" />
                  </InputGrid>

                  <SubTitleGrid subtitle="Andere" />
                  <InputGrid>
                    <StyledNumberField name="width" label="Breite" unit="px" />
                  </InputGrid>
                  <InputGrid>
                    <StyledNumberField name="height" label="Höhe" unit="px" />
                  </InputGrid>
                  <InputGrid>
                    <StyledNumberField name="maxres" label="max. Auflösung" unit="px" />
                  </InputGrid>
                  <InputGrid>
                    <StyledSelect
                      name="showRadarEasing"
                      label="Show radar easing"
                      items={easingOptions.map((o, i) => createOption(easingOptions[i], o))}
                    />
                  </InputGrid>
                </Grid>
              </Grid>
              {radarConfig && (
                <HalfRowGrid style={{ position: "relative" }}>
                  <Paper style={{ position: "sticky", top: 0, margin: "auto", width: values.width }}>
                    <RadarChart
                      config={{
                        ...values,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        views: [
                          {
                            id: "ClassicView",
                            name: "Classic",
                            icon: "ICON_STAR",
                            rotation: 0.0,
                            fields: {
                              name: "name",
                              section: "quadrant",
                              ring: "ring",
                              text: "description",
                              class: "isNew",
                              color: "quadrant"
                            },
                            section_order: "AUTO",
                            section_names: {
                              "1. Wahrnehmungs- und Kommunikationsunterstützung":
                                "Wahrnehmungs- und Kommunikationsunterstützung",
                              "2. Interaktionsunterstützung": "Interaktionsunterstützung",
                              "3. Navigations-/Orientierungsunterstützung": "Navigations-/Orientierungsunterstützung",
                              "4. Konkrete Arbeitsunterstützung": "Konkrete Arbeitsunterstützung",
                              "5. Lernunterstützung": "Lernunterstützung",
                              "6. Physische Unterstützung": "Physische Unterstützung",
                              "7. Psychische Unterstützung": "Psychische Unterstützung"
                            },
                            ring_order: "AUTO",
                            ring_names: {
                              Sinnesbehinderung: "Sinnesbehinderung",
                              "Psychische Störung": "Psychische Störung",
                              Lernbehinderung: "Lernbehinderung",
                              Körperbehinderung: "Körperbehinderung"
                              // "Chronische Erkrankung": "Chronische Erkrankung"
                            },
                            colors: {
                              "languages-and-frameworks": "#da4e4e",
                              "1. Wahrnehmungs- und Kommunikationsunterstützung": values.gruppe1,
                              "2. Interaktionsunterstützung": values.gruppe2,
                              "3. Navigations-/Orientierungsunterstützung": values.gruppe3,
                              "4. Konkrete Arbeitsunterstützung": values.gruppe4,
                              "5. Lernunterstützung": values.gruppe5,
                              "6. Physische Unterstützung": values.gruppe6,
                              "7. Psychische Unterstützung": values.gruppe7
                            },
                            class_order: ["TRUE", "FALSE"],
                            class_names: {
                              TRUE: "New or moved",
                              FALSE: "No change"
                            },
                            class_symbols: {
                              TRUE: "SYMBOL_TRIANGLE",
                              FALSE: "SYMBOL_CIRCLE"
                            }
                          }
                        ]
                      }}
                      data={[]}
                    />
                  </Paper>
                </HalfRowGrid>
              )}
              <FullRowGrid>
                <Button type="submit" variant="contained" color="primary">
                  Speichern
                </Button>
              </FullRowGrid>
            </MainGridContainer>
          </Form>
        )}
      </Formik>
      {apiError && errorAlert.show()}
      <successAlert.Component />
      <errorAlert.Component />
    </div>
  );
}
