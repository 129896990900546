import React, { Dispatch, SetStateAction } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { drawerWidth } from "../Navigation";
import Settings from "./Settings";
import Logo from "../Logo";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  logo: {
    position: "absolute",
    transform: "translate(-50%)",
    left: "50%",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      transform: "none",
      left: 0,
      marginRight: theme.spacing(2)
    },
    "& svg": {
      height: 45,
      [theme.breakpoints.down("sm")]: {
        height: 40
      }
    }
  },
  menuButton: {
    marginRight: 46,
    marginLeft: -8,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
      marginLeft: -12
    }
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "1.75em",
      borderLeft: "1px rgba(255,255,255,0.5) solid",
      paddingLeft: theme.spacing(2)
    }
  },
  popOverList: {
    minWidth: 100,
    "& .MuiListItemIcon-root": {
      minWidth: 30
    },
    "& .MuiTypography-root": {
      color: theme.palette.grey["500"],
      fontSize: "0.95em"
    }
  }
}));

interface HeaderProps {
  title: string | undefined;
  navOpen: boolean;
  handleNavOpen: Dispatch<SetStateAction<boolean>>;
}

export default function Header({ title, navOpen, handleNavOpen }: HeaderProps): JSX.Element {
  const classes = useStyles();

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, navOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        {/* TODO remove ts-ignore */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleNavOpen}
          className={clsx(classes.menuButton, navOpen && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.logo}>
          <Logo negative />
        </div>
        {title && (
          <Typography variant="h1" color="inherit" noWrap className={classes.title}>
            {title}
          </Typography>
        )}
        <Settings />
      </Toolbar>
    </AppBar>
  );
}
