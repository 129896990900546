import React, { useState } from "react";
import { Chip, darken, fade, Fade, InputAdornment, InputBase, Slide, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { CheckOutlined, ClearOutlined, Search } from "@material-ui/icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface TableSearchBarProps {
  name?: string;
  setSearchKeys: (keys: string[]) => void;
  searchKeys: string[];
}

export default function TableSearchBar(props: TableSearchBarProps) {
  const classes = useStyles();

  const [newSearchKey, setNewSearchKey] = useState("");

  const addSearchKey = () => {
    if (props.searchKeys.indexOf(newSearchKey) < 0) {
      props.setSearchKeys([...props.searchKeys, newSearchKey]);
      setNewSearchKey("");
    }
  };

  const removeSearchKey = (t: string) => {
    props.setSearchKeys(props.searchKeys.filter((oldTag) => oldTag !== t));
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    switch (e.key) {
      case "Enter":
        return addSearchKey();
      case "Escape":
        return setNewSearchKey("");
      default:
        return null;
    }
  };

  const chipList = props.searchKeys.map((tag, i) => (
    <Slide in direction="right">
      <Chip className={classes.chip} key={`${tag}-${i + 1}`} label={tag} onDelete={() => removeSearchKey(tag)} />
    </Slide>
  ));
  const hasSearchKey = chipList.length > 0;

  return (
    <InputBase
      fullWidth
      placeholder={`Suche${props.name ? ` in ${props.name}` : ""}`}
      onKeyUp={handleKeyUp}
      value={newSearchKey}
      onChange={(e) => setNewSearchKey(e.target.value)}
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput
      }}
      startAdornment={<Search />}
      endAdornment={
        newSearchKey || hasSearchKey ? (
          <Fade in={Boolean(newSearchKey || hasSearchKey)}>
            <InputAdornment position="end">
              {newSearchKey && props.searchKeys.indexOf(newSearchKey) < 0 && (
                <Tooltip title="add search term">
                  <IconButton size="small" onClick={addSearchKey}>
                    <CheckOutlined />
                  </IconButton>
                </Tooltip>
              )}
              {hasSearchKey && (
                <Tooltip title="clear search">
                  <IconButton size="small" onClick={() => props.setSearchKeys([])}>
                    <ClearOutlined />
                  </IconButton>
                </Tooltip>
              )}
              {hasSearchKey && <div className={classes.chipList}>{chipList}</div>}
            </InputAdornment>
          </Fade>
        ) : undefined
      }
      inputProps={{ "aria-label": "scuhe" }}
    />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0, 2),
      height: 56,
      overflow: "hidden",
      backgroundColor: theme.palette.background.default,
      "&:hover": {
        backgroundColor: darken(theme.palette.background.default, 0.05)
      }
    },
    inputInput: {
      padding: theme.spacing(1),
      minWidth: 100
    },
    chipList: {
      paddingLeft: theme.spacing(1),
      transition: theme.transitions.create("height"),
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "flex-end"
    },
    chip: {
      margin: theme.spacing(0, 0.5, 0, 0),
      backgroundColor: fade(theme.palette.secondary.dark, 0.2),
      border: `1px solid ${theme.palette.secondary.dark}`,
      "& svg": {
        color: theme.palette.secondary.main,
        transition: theme.transitions.create("color"),
        "&:hover": {
          color: theme.palette.secondary.dark
        }
      }
    }
  })
);
