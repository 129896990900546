import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { GenericTableDataEntry, StyledTableRowProps } from "./index";
import StyledTextInput from "../Inputs/TextInput";
import StyledNumberField from "../Inputs/NumbertInput";
import StyledSwitch from "../Inputs/Switch";

const maxLines = 2;
const lh = "1.4rem";
const useStyles = makeStyles((theme) => ({
  row: {},
  cell: {
    position: "relative",
    maxWidth: 220,
    "& h6": {
      overflow: "hidden",
      maxHeight: `calc(${lh} * ${maxLines})`,
      textOverflow: "ellipsis"
    }
  }
}));

export default function StyledTableRow<T extends GenericTableDataEntry>({
  data,
  isItemSelected,
  labelId,
  onSelectOne,
  withFields,
  id
}: StyledTableRowProps<T>) {
  const classes = useStyles();
  return (
    <TableRow
      className={classes.row}
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={(event: React.MouseEvent<unknown, MouseEvent>) => onSelectOne(event, data.id)}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </TableCell>
      {Object.entries(data).map(([k, v], i) => {
        const fieldName = `newData[${id}].${k}`;
        if (withFields) {
          let field;
          switch (typeof v) {
            case "number":
              field = <StyledNumberField name={fieldName} label="" />;
              break;
            case "boolean":
              field = <StyledSwitch name={fieldName} label="" left="Nein" right="Ja" />;
              break;
            case "string":
            default:
              field = <StyledTextInput name={fieldName} label="" multiline rowsMax={2} />;
          }
          if (k.toLowerCase() === "id") field = null;
          return <TableCell key={`cell-${i + 1}`}>{field}</TableCell>;
        }
        return (
          <TableCell key={`cell-${i + 1}`} className={classes.cell}>
            <Typography variant="subtitle1">{v}</Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
}
