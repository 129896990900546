import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Chart from "./Chart";
import Deposits from "./Deposits";
import Orders from "./Orders";
import Base from "../../Base";
import StyledPaper from "../../Base/Paper";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  }
}));

export default function Dashboard(): JSX.Element {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Base title="Dashboard">
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <StyledPaper className={fixedHeightPaper}>
            <Chart />
          </StyledPaper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <StyledPaper className={fixedHeightPaper}>
            <Deposits />
          </StyledPaper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <StyledPaper className={classes.paper}>
            <Orders />
          </StyledPaper>
        </Grid>
      </Grid>
    </Base>
  );
}
