import React, { ReactNode } from "react";
import { fade, IconButtonProps, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

export const useIconButtonStyles = makeStyles((theme) => ({
  iconButton: {
    transition: theme.transitions.create(["background", "color", "transform"]),
    "&:active": {
      transform: "scale(0.9)"
    }
  },
  light: {
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.background.default
    }
  },
  dark: {
    background: theme.palette.primary.main,
    color: theme.palette.background.paper,
    "&:hover": {
      background: theme.palette.primary.dark
    },
    "&.Mui-disabled": {
      color: theme.palette.background.paper,
      background: fade(theme.palette.primary.light, 0.5),
      boxShadow: theme.shadows[3]
    }
  }
}));

type StyledIconButtonProps = IconButtonProps & {
  title: string;
  icon: ReactNode;
  variant?: "light" | "dark";
};

export default function StyledIconButton({
  title,
  icon,
  variant,
  className: otherClasses,
  ...props
}: StyledIconButtonProps): JSX.Element {
  const classes = useIconButtonStyles();
  return (
    <Tooltip title={title}>
      <IconButton
        className={clsx(otherClasses && otherClasses, variant && classes[variant], classes.iconButton)}
        {...props}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}
