import React, { useRef, useState } from "react";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Button, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronRightOutlined, Publish } from "@material-ui/icons";
import { GridRowBreak, HalfRowGrid, MainGridContainer, OneForthGrid, ThreeFourthsGrid } from "../../Base/Grid";
import StyledTable from "../../Base/Table";
import { HeadCell } from "../../Base/Table/types";
import toCamelCase, { handleDjangoApiError } from "../helpers";
import { technologyValidationSchema } from "./TechnologyForm";
import StyledPaper from "../../Base/Paper";
import useAlert from "../../Base/Dialog/Alert";
import { Technology } from "./helpers";
import StyledDialog from "../../Base/Dialog";
import StyledIconButton from "../../Base/IconButton";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 320,
    minHeight: 150,
    margin: -1,
    position: "relative"
  },
  uploadButton: {
    width: "100%",
    textAlign: "center",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",

    "& [type=file]": {
      cursor: "inherit",
      display: "block",
      fontSize: "999px",
      filter: "alpha(opacity=0)",
      minHeight: "100%",
      minWidth: "100%",
      opacity: "0",
      position: "absolute",
      right: "0",
      textAlign: "right",
      top: "0"
    }
  },
  transferButton: {
    zIndex: 2,
    position: "sticky",
    top: "50%",
    left: "70%",
    marginBottom: -75
  },
  blur: {
    filter: "blur(1.5px)",
    transition: theme.transitions.create("filter", {
      easing: theme.transitions.easing.easeIn
    }),
    "&:hover": {
      filter: "none"
    }
  }
}));

// function isNumeric(str: string): boolean {
//   return (
//     !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
//     !Number.isNaN(parseFloat(str))
//   ); // ...and ensure strings of whitespace fail
// }

interface TechnologyTable {
  newData: Technology[];
}

export const mapKeyNames = {
  name: "Name",
  description: "Beschreibung",
  quadrant: "Gruppe",
  ring: "Kreis"
  // isNew: "Ist Neu?"
};

function hasDuplicates(array: any[]): boolean {
  return new Set(array).size !== array.length;
}

interface UploadTechnologyProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function UploadTechnology({ open, setOpen }: UploadTechnologyProps): JSX.Element {
  const classes = useStyles();

  const [head, setHead] = useState<HeadCell<any>[]>([]);
  const [excelData, setExcelData] = useState<Array<Record<string, unknown>>>([]);
  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<string>("");
  const [technologies, setTechnologies] = useState<Technology[]>([]);

  const successUploadAlert = useAlert({ message: "Excel-Daten geladen", severity: "success" });
  const successDeleteAlert = useAlert({ message: "Einträge gelöscht", severity: "success" });
  const apiErrorAlert = useAlert({ message: apiError || "", severity: "error" });

  const history = useHistory();
  // console.log(history);

  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = async (files: FileList | null): Promise<void> => {
    // setTechnologies([]);
    if (files === null) return;
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("table", files[0]);
      const response = await axios.post("convert/table", formData);
      const tableData: Array<Record<string, unknown>> = toCamelCase(JSON.parse(response.data.data));

      setOrderBy(Object.keys(tableData[1])[0]);

      const tableHead = Object.keys(tableData[1]).map((k) => {
        return {
          disablePadding: false,
          id: k,
          label: _.snakeCase(k),
          numeric: false
        };
      });
      tableHead.push({
        disablePadding: false,
        id: "id",
        label: "ID",
        numeric: false
      });
      setHead(tableHead);
      // eslint-disable-next-line no-param-reassign,no-return-assign
      tableData.forEach((t, i) => (t.id = i + 1));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setExcelData(tableData);
      successUploadAlert.show();
      setApiError(undefined);
      setIsUploading(false);
    } catch (e) {
      const errorMessage = handleDjangoApiError(e);
      setApiError(errorMessage === "Internal Server Error" ? "Invalid file" : errorMessage);
    }
  };

  const [searchKeys, setSearchKeys] = React.useState<string[]>([]);
  const filterSearchKey = (item: any) =>
    // searchKeys.some((k) => item.ownerEmail.includes(k) || item.id.includes(k)) ||
    searchKeys.every((k) => Object.values(item).some((i) => String(i).toLowerCase().includes(k.toLowerCase())));

  const handleDeleteRows = (ids: (string | number)[]) => {
    const newData = excelData.filter((row) => !ids.includes(row.id as string | number));
    setExcelData([...newData]);
    successDeleteAlert.show();
  };

  const [techSearchKeys, setTechSearchKeys] = React.useState<string[]>([]);
  const filterTechSearchKey = (item: any) =>
    techSearchKeys.every((k) => Object.values(item).some((i) => String(i).toLowerCase().includes(k.toLowerCase())));

  const handleDeleteTechRows = (ids: (string | number)[]) => {
    const newData = technologies.filter((row) => !ids.includes(row.id as string | number));
    // const newData = _.reject(data, (row) => row.id === id);
    setTechnologies([...newData]);
    successDeleteAlert.show();
  };

  const selectOptions = ["", "name", "quadrant", "ring", "description"];

  const excelTable = excelData && excelData.length > 0 && (
    <StyledPaper>
      <Formik
        initialValues={{
          selectedKeys: {}
        }}
        validationSchema={Yup.object({
          // selectedKeys: Yup.object().
        })}
        onSubmit={(values, { setFieldError }) => {
          const selected = _.values(values.selectedKeys);
          if (selected.length < 4) {
            selected.forEach((s) =>
              setFieldError(`selectedKeys.${String(s).split("-")[0]}`, "Must select all 4 field")
            );
          } else if (hasDuplicates(selected.map((s) => String(s).split("-")[1]))) {
            selected.forEach((s) =>
              setFieldError(`selectedKeys.${String(s).split("-")[0]}`, "Each field can only be selected once")
            );
          } else {
            const newData = excelData.map((row) => {
              const newRow: { [k: string]: unknown } = {};
              selected.forEach((s) => {
                const [from, to] = String(s).split("-");
                if (from !== "undefined") newRow[String(to)] = row[from];
              });
              newRow.id = row.id;
              return Object.keys(newRow)
                .sort()
                .reduce((obj, key) => {
                  // eslint-disable-next-line no-param-reassign,@typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  // eslint-disable-next-line no-param-reassign
                  obj[key] = newRow[key];
                  return obj;
                }, {});
            });
            setTechnologies(newData as any);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <StyledIconButton
              className={classes.transferButton}
              title="Daten nach Technolgie übertragen"
              icon={<ChevronRightOutlined />}
              type="submit"
              variant="dark"
              // disabled={_.keys(values.selectedKeys).filter((k) => k !== "undefined").length !== 4}
            >
              Submit
            </StyledIconButton>
            <div style={{ marginTop: -20 }}>
              <StyledTable
                title="Excel"
                headCells={head}
                data={excelData as any}
                orderBy={orderBy}
                selectOptions={selectOptions}
                filterSearchKey={filterTechSearchKey}
                toolbarActions={[]}
                handleDeleteRow={handleDeleteRows}
                setSearchKeys={setTechSearchKeys}
                searchKeys={techSearchKeys}
              />
            </div>
          </Form>
        )}
      </Formik>
    </StyledPaper>
  );

  const technologyTableRef = useRef(null);
  const technologyHeadCells = [
    { id: "description", numeric: false, disablePadding: false, label: "Beschreibung" },
    { id: "id", numeric: true, disablePadding: false, label: "ID" },
    { id: "name", numeric: false, disablePadding: false, label: "Technologie" },
    { id: "quadrant", numeric: false, disablePadding: false, label: "Gruppe" },
    { id: "ring", numeric: false, disablePadding: false, label: "Kreis" }
    // { id: "isNew", numeric: false, disablePadding: false, label: "Ist neu?" }
  ];
  const technolyTable = (excelData.length > 0 || technologies.length > 0) && (
    <StyledPaper>
      <Formik<TechnologyTable>
        innerRef={technologyTableRef}
        initialValues={{ newData: technologies }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          newData: Yup.array().of(Yup.object().shape(technologyValidationSchema))
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const d = values.newData.map((v) => _.omit(v, "id"));
          try {
            await axios.post("technologies", d);
            // successAlert.show();
            setApiError(undefined);
            setSubmitting(false);
            history.go(0);
          } catch (e) {
            setApiError(handleDjangoApiError(e));
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, errors, values }) => (
          <Form onSubmit={handleSubmit}>
            <StyledTable
              title="Technologie"
              withFields
              headCells={technologyHeadCells as any}
              data={technologies as any}
              orderBy={orderBy}
              filterSearchKey={filterSearchKey}
              toolbarActions={[]}
              handleDeleteRow={handleDeleteTechRows}
              setSearchKeys={setSearchKeys}
              searchKeys={searchKeys}
            />
          </Form>
        )}
      </Formik>
    </StyledPaper>
  );

  const handleCloseDialog = () => {
    setExcelData([]);
    setTechnologies([]);
    setOpen(false);
  };

  const uploadField = isUploading ? (
    <CircularProgress color="secondary" />
  ) : (
    <div className={classes.uploadButton}>
      <Publish /> Hier klicken, um den Dateiuploader auszulösen
      <input
        type="file"
        accept=".xls, .xlsx, .xlsm, .xlsb, .odf, .ods, .odt"
        onChange={(e) => handleFileUpload(e.target.files)}
      />
    </div>
  );

  return (
    <StyledDialog
      title="Technologie-Tabelle hochladen"
      open={open}
      onClose={() => handleCloseDialog()}
      maxWidth="xl"
      footer={
        <>
          <Button color="secondary" variant="outlined" style={{ marginRight: 8 }} onClick={() => setOpen(false)}>
            Abbrechen
          </Button>
          <Button
            color="primary"
            variant="contained"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={() => technologyTableRef.current.handleSubmit()}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            disabled={technologies.length < 1}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {/* {console.log(technologyTableRef?.current?.errors?.newData?.length > 0)} */}
            Speichern
          </Button>
        </>
      }
    >
      <MainGridContainer noPadding className={classes.container}>
        <HalfRowGrid>{excelData.length < 1 && technologies.length < 1 && uploadField}</HalfRowGrid>
        <GridRowBreak />
        {technologies.length > 0 ? (
          <>
            <OneForthGrid className={classes.blur}>{excelTable}</OneForthGrid>
            <ThreeFourthsGrid>{technolyTable}</ThreeFourthsGrid>
          </>
        ) : (
          <>
            <ThreeFourthsGrid>{excelTable}</ThreeFourthsGrid>
            <OneForthGrid className={classes.blur}>{technolyTable}</OneForthGrid>
          </>
        )}

        {apiError && apiErrorAlert.show()}
        <apiErrorAlert.Component />
        <successUploadAlert.Component />
        <successDeleteAlert.Component />
      </MainGridContainer>
    </StyledDialog>
  );
}
