import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReportProblemOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      background: theme.palette.primary.dark,
      color: theme.palette.background.paper
    }
  }
}));

type WarningConfig = {
  message: string;
  callback: () => void;
};

export default function useWarning(config: WarningConfig) {
  const [showWarning, setShowWarning] = useState(false);
  const classes = useStyles();
  const onClickCancel = () => {
    setShowWarning(false);
  };
  const onClickConfirm = () => {
    Promise.resolve(config.callback()).then(() => {
      setShowWarning(false);
    });
  };

  const NewWarningComponent = () => (
    <Dialog
      maxWidth="xs"
      className={classes.dialog}
      open={showWarning}
      onClose={() => setShowWarning(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <ReportProblemOutlined style={{ verticalAlign: "text-bottom", marginRight: 8 }} />
        Moment mal...
      </DialogTitle>
      <DialogContent>{config.message}</DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} color="primary" variant="contained" autoFocus>
          Lieber nicht
        </Button>
        <Button onClick={onClickConfirm} color="default" variant="contained">
          Ja, sicher!
        </Button>
      </DialogActions>
    </Dialog>
  );

  return {
    show: () => {
      setShowWarning(true);
    },
    Dialog: NewWarningComponent
  };
}
