import { camelCase, mapKeys, snakeCase } from "lodash";

interface djangoApiError {
  response?: { status: string; data: { detail?: string; statusText?: string } };
  request?: string;
  message?: string;
  config?: string;
}

export function handleDjangoApiError(error: djangoApiError): string {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.warn("response", error.response);
    return error.response.data.detail || error.response.data.statusText || "Internal Server Error";
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.warn("request", error.request);
    return error.request;
  }
  if (error.message) {
    // Something happened in setting up the request that triggered an Error
    // console.warn("message", JSON.parse(error.message).statusText);
    // console.log("bla", error);
    return JSON.parse(error.message).statusText;
  }
  console.warn("config", error.config);
  return error.config || "Unknown error";
}

// TODO solve the following typescript problems

export default function toCamelCase<T>(data: T | T[]): T | T[] {
  if (Array.isArray(data)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.map((obj) => mapKeys(obj, (v: T, k: string) => camelCase(k)));
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return mapKeys(data, (v: T, k: string) => camelCase(k));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // return mapKeys(obj, (v, k: keyof T) => camelCase(k as string));
}

export function toSnakeCase<T>(data: T | T[]): T | T[] {
  if (Array.isArray(data)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.map((obj) => mapKeys(obj, (v: T, k: string) => snakeCase(k)));
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return mapKeys(data, (v: T, k: string) => snakeCase(k));
}
