import React, { Dispatch, ReactNode, ReactText, SetStateAction, useState } from "react";
import { IconButton, Toolbar, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import { SpeedDialAction } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import { useToolbarStyles } from "./styles";
import TableSearchBar from "../Search";
import StyledIconButton from "../IconButton";
import useWarning from "../Dialog/Warning";

export interface TableToolbarAction {
  title: string;
  icon: ReactNode;
  onClick: () => void;
}

interface StyledTableToolbarProps {
  title?: string;
  setSearchKeys: (keys: string[]) => void;
  selected: (number | string)[];
  setSelected: Dispatch<SetStateAction<ReactText[]>>;
  searchKeys: string[];
  handleDeleteRow: (id: (number | string)[]) => void;
  actions?: TableToolbarAction[];
  // onSelectedActions?: TableToolbarAction[];
}

export const StyledTableToolbar = (props: StyledTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { selected, setSelected, handleDeleteRow, actions, title, searchKeys, setSearchKeys } = props;
  const numSelected = selected.length;
  const [speedDialOpen, setSpeedDialOpen] = useState(false);

  // TODO use this on parent
  const warning = useWarning({
    message: `Möchtest Du diese ${selected.length} ${title} Einträge wirklich löschen? \nDu wirst sie nicht mehr abrufen können.`,
    callback: () => {
      handleDeleteRow(selected);
      setSelected([]);
    }
  });

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <warning.Dialog />
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} {title} Einträge wurden ausgewählt
        </Typography>
      ) : (
        <>
          {actions && actions.length === 1 && (
            <StyledIconButton
              className={classes.singleActionButton}
              icon={actions[0].icon}
              title={actions[0].title}
              onClick={actions[0].onClick}
              variant="dark"
            />
          )}
          {actions && actions.length > 1 && (
            <SpeedDial
              ariaLabel={`${title} SpeedDial`}
              className={clsx(classes.speedDial, speedDialOpen && classes.openSpeedDial)}
              icon={<SpeedDialIcon />}
              onClose={() => setSpeedDialOpen(false)}
              onOpen={() => setSpeedDialOpen(true)}
              open={speedDialOpen}
              direction="right"
            >
              {actions.map((a) => (
                <SpeedDialAction
                  icon={a.icon}
                  title={a.title}
                  tooltipTitle={a.title}
                  tooltipPlacement="bottom"
                  onClick={a.onClick}
                />
              ))}
            </SpeedDial>
          )}
          <TableSearchBar name={title} searchKeys={searchKeys} setSearchKeys={setSearchKeys} />
        </>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={() => warning.show()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default StyledTableToolbar;
