import React, { useState } from "react";
import axios from "axios";
import { IconButton, Toolbar, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import { SpeedDialAction } from "@material-ui/lab";
import { Add, Publish } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import TableSearchBar from "../../../Base/Search";
import StyledDialog from "../../../Base/Dialog";
import TechnologyForm from "../TechnologyForm";
import UploadTechnology from "../UploadTechnology";
import { handleDjangoApiError } from "../../helpers";
import { useToolbarStyles } from "../../../Base/Table/styles";
import { EnhancedTableToolbarProps } from "../../../Base/Table/types";
import useWarning from "../../../Base/Dialog/Warning";

export const TechnologyTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { selected } = props;
  const numSelected = selected.length;
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const history = useHistory();

  const warning = useWarning({
    message: `Möchtest Du diese ${selected.length} Einträge wirklich löschen? Du wirst sie nicht mehr abrufen können.`,
    callback: () => {
      selected.forEach((id) => {
        handleDelete(Number(id)).then(() => history.go(0));
      });
    }
  });

  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`technologies/${id}`);
      // console.log(r);
    } catch (e) {
      console.warn(handleDjangoApiError(e));
    }
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            className={clsx(classes.speedDial, speedDialOpen && classes.openSpeedDial)}
            icon={<SpeedDialIcon />}
            onClose={() => setSpeedDialOpen(false)}
            onOpen={() => setSpeedDialOpen(true)}
            open={speedDialOpen}
            direction="right"
          >
            <SpeedDialAction
              icon={<Add />}
              title="Technologie hinzufügen"
              tooltipTitle="Technologie hinzufügen"
              tooltipPlacement="bottom"
              onClick={() => {
                setAddDialogOpen(true);
                setSpeedDialOpen(false);
              }}
            />
            <SpeedDialAction
              icon={<Publish />}
              title="CSV hochladen"
              tooltipTitle="CSV hochladen"
              tooltipPlacement="bottom"
              onClick={() => {
                setUploadDialogOpen(true);
                setSpeedDialOpen(false);
              }}
            />
          </SpeedDial>
          <TableSearchBar searchKeys={props.searchKeys} setSearchKeys={props.setSearchKeys} />
          <StyledDialog title="Technologie hinzufügen" open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
            <TechnologyForm setOpen={setAddDialogOpen} />
          </StyledDialog>
          <UploadTechnology open={uploadDialogOpen} setOpen={setUploadDialogOpen} />
        </>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={() => warning.show()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      <warning.Dialog />
    </Toolbar>
  );
};

export default TechnologyTableToolbar;
