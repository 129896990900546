import React, { Dispatch, PropsWithChildren, SetStateAction } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ClassIcon from "@material-ui/icons/Class";
import BarChartIcon from "@material-ui/icons/BarChart";
import { Tabs, Tooltip } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { theme } from "../Theme";
import { TabLink, useTabsWithRouter } from "./RouterTabs";
import typographyStyles from "../Theme/Typography";

export const drawerWidth = 240;
const drawerBgColor = theme.palette.secondary.dark;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    background: drawerBgColor,
    color: theme.palette.getContrastText(drawerBgColor),
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    border: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    "& .MuiListItemIcon-root": {
      color: theme.palette.getContrastText(drawerBgColor)
    }
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    },
    [theme.breakpoints.down("xs")]: {
      width: 5
    }
  },
  tabs: {
    display: "flex",
    width: drawerWidth,
    height: "100%",
    backgroundColor: theme.palette.secondary.dark,

    "& .MuiButtonBase-root": {
      ...typographyStyles.navLink,
      padding: theme.spacing(0, 2.5),
      transition: theme.transitions.create(["color", "background"], {
        duration: theme.transitions.duration.shorter
      })
    },
    "& .MuiTab-wrapper": {
      alignItems: "center!important",
      "& svg": { marginBottom: "0!important" }
    },
    "& .Mui-selected": {
      backgroundColor: "#E3EBF3",
      color: theme.palette.secondary.dark
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#E3EBF3"
    },
    "& .MuiTabs-flexContainerVertical": {
      height: "100%",

      "& .MuiTab-labelIcon": {
        minHeight: 60,
        margin: theme.spacing(1, 0, 0, 1),
        borderBottomLeftRadius: 5,
        borderTopLeftRadius: 5,

        "& .MuiTab-wrapper": {
          alignItems: "stretch",
          flexDirection: "row",
          justifyContent: "flex-start",
          textTransform: "none",

          "& svg": {
            marginRight: theme.spacing(3)
          }
        }
      }
    }
  }
}));

interface NavigationProps {
  open: boolean;
  handleNavClose: Dispatch<SetStateAction<boolean>>;
}

const WithTooltip = ({ label, open, children }: PropsWithChildren<any>): JSX.Element => {
  if (!open) {
    return (
      <Tooltip title={label} placement="right">
        {children}
      </Tooltip>
    );
  }
  return <>{children}</>;
};

export default function Navigation({ open, handleNavClose }: NavigationProps): JSX.Element {
  const classes = useStyles();
  const { tabsValue } = useTabsWithRouter("", ["/dashboard", "/technologies", "/charts"], "/technologies");
  return (
    <Drawer
      variant="permanent"
      classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        {/* TODO remove ts-ignore */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <IconButton onClick={handleNavClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabsValue}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <TabLink
          value="/dashboard"
          label="Dashboard"
          icon={
            <WithTooltip open={open} label="Dashboard">
              <DashboardIcon />
            </WithTooltip>
          }
        />
        <TabLink
          value="/technologies"
          label="Technologien"
          icon={
            <WithTooltip open={open} label="Technologien">
              <ClassIcon />
            </WithTooltip>
          }
        />
        <TabLink
          value="/charts"
          label="Charts"
          icon={
            <WithTooltip open={open} label="Charts">
              <BarChartIcon />
            </WithTooltip>
          }
        />
      </Tabs>
    </Drawer>
  );
}
