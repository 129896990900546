import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { InfoOutlined } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { HeadCell, Order } from "./types";
import { useTableStyles } from "./styles";
import { getComparator, stableSort } from "./helpers";
import StyledTableHead from "./Head";
import { StyledTableToolbar, TableToolbarAction } from "./Toolbar";
import StyledTableRow from "./Row";
import StyledSelect from "../Inputs/SelectInput";
import { createOption } from "../../secure/Team/helpers";
import { mapKeyNames } from "../../secure/Technologies/UploadTechnology";

export interface GenericTableDataEntry {
  id: number | string;
}

export interface StyledTableRowProps<T extends GenericTableDataEntry> {
  data: T;
  isItemSelected: boolean;
  labelId: string;
  onSelectOne: (event: React.MouseEvent<unknown>, id: string | number) => void;
  withFields?: boolean;
  id: string | number;
}

interface StyledTableProps<T extends GenericTableDataEntry> {
  title?: string;
  data: T[];
  headCells: HeadCell<T>[];
  orderBy: keyof T;
  toolbarActions: TableToolbarAction[];
  selectOptions?: string[];
  filterSearchKey: (item: T) => boolean;
  handleDeleteRow: (id: (number | string)[]) => void;
  setSearchKeys: (keys: string[]) => void;
  searchKeys: string[];
  withFields?: boolean;
}

export default function StyledTable<T extends GenericTableDataEntry>(props: StyledTableProps<T>) {
  const classes = useTableStyles();
  const {
    data,
    orderBy: ordBy,
    filterSearchKey,
    toolbarActions,
    headCells,
    searchKeys,
    setSearchKeys,
    handleDeleteRow,
    selectOptions,
    title,
    withFields
  } = props;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof T>(ordBy);
  const [selected, setSelected] = React.useState<(string | number)[]>([]);
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(40);

  const filteredRows = data.filter(filterSearchKey);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectOne = (event: React.MouseEvent<unknown>, id: string | number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: (string | number)[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setDense(event.target.checked);
  // };

  let selectOptionsRow;
  if (selectOptions) {
    selectOptionsRow = (
      <TableRow>
        <TableCell>
          <Tooltip
            title="Für jedes Technologiefeld die entsprechende Zeile in der Excel-Tabelle auswählen"
            placement="top-start"
          >
            <InfoOutlined color="action" style={{ position: "absolute" }} />
          </Tooltip>
        </TableCell>
        {headCells.map((c) => {
          const options = selectOptions?.map((label) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label === "" ? createOption("", label) : createOption(`${c.id}-${label}`, mapKeyNames[String(label)])
          );
          return (
            <TableCell key={c.label}>
              <StyledSelect name={`selectedKeys.${c.id}`} label="Feld" items={options} />
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  const isSelected = (id: string | number) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <StyledTableToolbar
        title={title}
        actions={toolbarActions}
        searchKeys={searchKeys}
        setSearchKeys={setSearchKeys}
        selected={selected}
        setSelected={setSelected}
        handleDeleteRow={handleDeleteRow}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          // size={dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <StyledTableHead<T>
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredRows.length}
            headCells={headCells}
          />
          <TableBody>
            {selectOptions && selectOptionsRow}
            {stableSort(filteredRows, getComparator<T>(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow
                  withFields={withFields}
                  key={row.id}
                  id={row.id}
                  data={row}
                  onSelectOne={handleSelectOne}
                  isItemSelected={isSelected(row.id)}
                  labelId={`enhanced-table-checkbox-${index}`}
                />
              ))}

            {emptyRows > 0 && (
              // <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 150]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> */}
    </div>
  );
}
