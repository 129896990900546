import React from "react";
import Title from "./Title";

export default function Chart(): JSX.Element {
  return (
    <>
      <Title>Today</Title>
    </>
  );
}
