import { Tooltip } from "@material-ui/core";
import React, { ReactNode } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";

interface NavigationItemProps {
  name: string;
  icon?: ReactNode;
  url: string;
  toolTip?: boolean;
  onClick?: () => void;
}

export default function NavigationItem({ name, icon, url, toolTip }: NavigationItemProps): JSX.Element {
  const navItem = (
    <li>
      <ListItem button component={Link} to={url}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={name} />
      </ListItem>
    </li>
  );

  const navItemWithToolTip = (
    <Tooltip title={name} placement="right">
      {navItem}
    </Tooltip>
  );

  return toolTip ? navItemWithToolTip : navItem;
}
