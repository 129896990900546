import React from "react";
import { InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import { useField } from "formik";
import { UiInput } from "./index";

type NumberFieldProps = UiInput<number> &
  TextFieldProps & {
    unit?: string;
  };

export default function StyledNumberField({ name, label, onChange, unit, ...props }: NumberFieldProps): JSX.Element {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [, meta, helpers] = useField<number>(name);

  const onChangeNumberField = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === "function") {
      onChange(Number(ev.target.value));
    }
    helpers.setValue(Number(ev.target.value));
  };

  return (
    <TextField
      fullWidth
      type="number"
      name={name}
      label={label}
      value={meta.value}
      onChange={onChangeNumberField}
      error={meta.touched && meta.error !== undefined}
      helperText={(meta.touched && meta.error) || ""}
      InputProps={unit ? { endAdornment: <InputAdornment position="end">{unit}</InputAdornment> } : undefined}
      {...props}
    />
  );
}
