import React, { ReactNode, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Navigation from "./Navigation";
import Header from "./Header";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="right">
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://ki-assist.de/">
        KI.ASSIST
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh"
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    minHeight: "calc(100vh - 140px)",
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto"
  }
}));

interface BaseProps {
  children: ReactNode;
  title: string;
}

export default function Base({ children, title }: BaseProps): JSX.Element {
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const getSessionCookie = async () => {
    try {
      await axios.get("user");
    } catch (e) {
      setRedirect(true);
    }
  };

  useEffect(() => {
    getSessionCookie();
  }, []);

  const [navOpen, setNavOpen] = useState(false);
  const handleNavClose = () => {
    setNavOpen(false);
  };

  return redirect ? (
    <Redirect to="/" />
  ) : (
    <div className={classes.root}>
      <Header title={title} navOpen={navOpen} handleNavOpen={setNavOpen} />
      <Navigation open={navOpen} handleNavClose={handleNavClose} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <>{children}</>
        </Container>
        <footer className={classes.footer}>
          <Copyright />
        </footer>
      </main>
    </div>
  );
}
