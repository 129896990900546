import axios from "axios";
import toCamelCase, { handleDjangoApiError } from "../../helpers";
import RadarConfig from "./types";

const getRadars = async (): Promise<RadarConfig[] | string> => {
  try {
    const response = await axios.get("charts/radar");
    return response.data.data.map((u: RadarConfig) => toCamelCase(u));
  } catch (e) {
    return handleDjangoApiError(e);
  }
};
export default getRadars;

export const getRadar = async (id: number): Promise<RadarConfig | string> => {
  try {
    const response = await axios.get(`charts/radar/${id}`);
    return toCamelCase(response.data.data);
  } catch (e) {
    return handleDjangoApiError(e);
  }
};
