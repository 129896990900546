import React, { CSSProperties } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { EditOutlined } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
import { Technology } from "../helpers";
import StyledDialog from "../../../Base/Dialog";
import TechnologyForm from "../TechnologyForm";
import { useTableStyles } from "../../../Base/Table/styles";
import { HeadCell, Order } from "../../../Base/Table/types";
import { getComparator, stableSort } from "../../../Base/Table/helpers";
import { TechnologyTableToolbar } from "./Toolbar";
import TechnologyTableHead from "./Head";

const technologyHeadCells: HeadCell<Technology>[] = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  { id: "name", numeric: false, disablePadding: false, label: "Technologie" },
  { id: "quadrant", numeric: false, disablePadding: false, label: "Gruppe" },
  { id: "ring", numeric: false, disablePadding: false, label: "Kreis" }
  // { id: "isNew", numeric: false, disablePadding: false, label: "Ist neu?" }
];

interface t extends Technology {
  id: number;
}

interface TechnologyTableProps {
  technologies: t[];
}

export default function TechnologyTable({ technologies }: TechnologyTableProps) {
  const classes = useTableStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Technology>("id");
  const [selected, setSelected] = React.useState<number[]>([]);
  const [page, setPage] = React.useState(0);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [onEdit, setOnEdit] = React.useState<Technology | undefined>(undefined);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchKeys, setSearchKeys] = React.useState<string[]>([]);

  const filterSearchKey = (item: Technology) =>
    // searchKeys.some((k) => item.ownerEmail.includes(k) || item.id.includes(k)) ||
    searchKeys.every(
      (k) =>
        item.name.toLowerCase().includes(k.toLowerCase()) ||
        item.description.toLowerCase().includes(k.toLowerCase()) ||
        item.quadrant.toLowerCase().includes(k.toLowerCase()) ||
        item.ring.toLowerCase().includes(k.toLowerCase())
      // item.isNew
      //   .toString()
      //   // eslint-disable-next-line no-nested-ternary
      //   .includes(k === "Ja" || k === "ja" ? "true" : k === "Nein" || k === "nein" ? "false" : "null")
    );

  const filteredRows = technologies.filter(filterSearchKey);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Technology) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // console.log("checked");
      const newSelected = filteredRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelectOne = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleEdit = (id: number) => {
    const { ...c } = technologies.find((a) => a.id === id);
    setOnEdit(c);
    setEditDialogOpen(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, technologies.length - page * rowsPerPage);
  const overFlowEllipsis: CSSProperties = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    maxWidth: 400,
    overflow: "hidden"
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TechnologyTableToolbar selected={selected} searchKeys={searchKeys} setSearchKeys={setSearchKeys} />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
            <TechnologyTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
              headCells={technologyHeadCells}
            />
            <TableBody>
              {stableSort(filteredRows, getComparator<Technology>(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event: React.MouseEvent<unknown, MouseEvent>) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event: React.MouseEvent<unknown, MouseEvent>) => handleSelectOne(event, row.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell align="right" width={20}>
                        <Typography variant="subtitle1">{row.id}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">{row.name}</Typography>
                        <Typography variant="subtitle2" style={overFlowEllipsis}>
                          {row.description.replace(/<\/?[^>]+(>|$)/g, "")}
                        </Typography>
                      </TableCell>
                      <TableCell width={200}>
                        <Typography variant="subtitle1">{row.quadrant}</Typography>
                      </TableCell>
                      <TableCell width={200}>
                        <Typography variant="subtitle1">{row.ring}</Typography>
                      </TableCell>
                      {/* <TableCell width={150}> */}
                      {/*  <Typography variant="subtitle1">{row.isNew ? "Ja" : "Nein"}</Typography> */}
                      {/* </TableCell> */}
                      <TableCell width={20}>
                        <Tooltip title="Edit">
                          <IconButton onClick={() => handleEdit(row.id)}>
                            <EditOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <StyledDialog title="Edit Technologie" open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <TechnologyForm technology={onEdit} setOpen={setEditDialogOpen} />
        </StyledDialog>
      </Paper>
    </div>
  );
}
