import React, { useState } from "react";
import axios from "axios";
import { IconButton, Toolbar, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { Add } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import { useToolbarStyles } from "../../../../Base/Table/styles";
import { handleDjangoApiError } from "../../../helpers";
import StyledDialog from "../../../../Base/Dialog";
import TechnologyRadarForm from "../RadarForm";
import TableSearchBar from "../../../../Base/Search";
import StyledIconButton from "../../../../Base/IconButton";
import useWarning from "../../../../Base/Dialog/Warning";

interface RadarTableToolbarProps {
  setSearchKeys: (keys: string[]) => void;
  selected: string[];
  searchKeys: string[];
}

export const RadarTableToolbar = (props: RadarTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { selected } = props;
  const numSelected = selected.length;
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const history = useHistory();

  const warning = useWarning({
    message: `Möchtest Du diese ${selected.length} Einträge wirklich löschen? Du wirst sie nicht mehr abrufen können.`,
    callback: () => {
      selected.forEach((id) => {
        handleDelete(id).then(() => history.go(0));
      });
    }
  });

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`charts/radar/${id}`);
      // console.log(r);
    } catch (e) {
      console.warn(handleDjangoApiError(e));
    }
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <StyledIconButton
            icon={<Add />}
            title="Radar hinzufügen"
            onClick={() => setAddDialogOpen(true)}
            className={classes.singleActionButton}
            variant="dark"
          />
          <TableSearchBar searchKeys={props.searchKeys} setSearchKeys={props.setSearchKeys} />
          <StyledDialog
            maxWidth="xl"
            title="Radar hinzufügen"
            open={addDialogOpen}
            onClose={() => setAddDialogOpen(false)}
          >
            <TechnologyRadarForm />
          </StyledDialog>
        </>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={() => warning.show()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      <warning.Dialog />
    </Toolbar>
  );
};

export default RadarTableToolbar;
