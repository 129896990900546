import React, { useState } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  alert: {
    [theme.breakpoints.up("sm")]: {
      bottom: theme.spacing(5)
    }
  }
}));

type AlertConfig = {
  message: string;
  severity?: "success" | "info" | "warning" | "error";
};

export default function useAlert(config: AlertConfig) {
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(false);

  const NewAlertComponent = () => (
    <Snackbar className={classes.alert} open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
      <Alert onClose={() => setShowAlert(false)} severity={config.severity} elevation={3} variant="filled">
        {config.message.toUpperCase()}
      </Alert>
    </Snackbar>
  );

  return {
    show: () => {
      setShowAlert(true);
    },
    Component: NewAlertComponent
  };
}
