import React, { ReactNode } from "react";
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import StyledIconButton from "../IconButton";

const useDialogStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.primary.main
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

type StyledDialogProps = DialogProps & {
  title: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  footer?: ReactNode;
};

export default function StyledDialog({
  title,
  open,
  onClose,
  children,
  footer,
  ...props
}: StyledDialogProps): JSX.Element {
  const classes = useDialogStyles();

  const closeButton = (
    <StyledIconButton className={classes.close} title="Abbrechen" icon={<CloseIcon />} onClick={onClose} />
  );

  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">{title}</Typography>
        {closeButton}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  );
}
