import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Dashboard from "./components/secure/Dashboard";
import Team from "./components/secure/Team";
import SignIn from "./components/public/SignIn";
import ThemeProvider from "./components/Base/Theme";
import TestFormik from "./components/public/SignIn/testFormik";
import Profile from "./components/secure/Profile";
import Technologies from "./components/secure/Technologies";
import TechnologyRadar from "./components/secure/Charts/Radar";
import PublicChart from "./components/public/Charts/Radar";
import ErrorBoundary from "./components/Base/ErrorBoundary";

function App(): JSX.Element {
  return (
    <div className="App">
      <BrowserRouter>
        <ErrorBoundary>
          <ThemeProvider>
            {/* Info: menus at components/Base/Navigation and components/Base/Header */}
            <Route path="/" exact component={SignIn} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/technologies" component={Technologies} />
            <Route path="/charts" component={TechnologyRadar} />
            <Route path="/team" exact component={Team} />
            <Route path="/formik" component={TestFormik} />
            <Route path="/profile/:id" component={Profile} />
          </ThemeProvider>
          <Route path="/public/radar/:id" component={PublicChart} />
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
}

export default App;
