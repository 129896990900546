import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import StyledTextInput from "../../Base/Inputs/TextInput";
import StyledSelect from "../../Base/Inputs/SelectInput";
import StyledCheckbox from "../../Base/Inputs/Checkbox";
import Base from "../../Base";
import ColorInput, { isValidColor } from "../../Base/Inputs/ColorInput";
import StyledPasswordInput from "../../Base/Inputs/PasswordInput";
import StyledTextEditor from "../../Base/Inputs/TextEditor";

// And now we can use these
export default function TestFormik(): JSX.Element {
  return (
    <Base title="test Formik">
      <h1>Subscribe!</h1>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          acceptedTerms: false, // added for our checkbox
          jobType: "", // added for our select
          color: ""
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
          lastName: Yup.string().max(20, "Must be 20 characters or less").required("Required"),
          email: Yup.string().email("Invalid email address").required("Required"),
          acceptedTerms: Yup.boolean().required("Required").oneOf([true], "You must accept the terms and conditions."),
          jobType: Yup.string()
            .oneOf(["designer", "development", "product", "other"], "Invalid Job Type.")
            .required("Required"),
          color: Yup.string()
            .test(
              "valid-color",
              "Please inform a valid color. For more information about the available color names check www.w3schools.com/colors/colors_names.asp.",
              (v) => isValidColor(v || "")
            )
            .required("This field is required.")
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            // eslint-disable-next-line no-alert
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        <Form>
          <StyledTextInput label="First Name" name="firstName" type="text" placeholder="Jane" />
          <StyledTextInput label="Last Name" name="lastName" type="text" placeholder="Doe" />
          <StyledTextInput label="Email Address" name="email" type="email" placeholder="jane@formik.com" />
          <StyledSelect
            label="Job Type"
            name="jobType"
            items={[
              { value: "", label: "Select a job type" },
              { value: "designer", label: "Designer" },
              { value: "development", label: "Developer" },
              { value: "product", label: "Product Manager" },
              { value: "other", label: "Other" }
            ]}
          />
          <StyledTextEditor name="bla" label="bla" />
          <StyledCheckbox required name="acceptedTerms" label="I accept the terms and conditions" />
          <ColorInput name="color" label="Pick a color" />
          <StyledPasswordInput name="password" label="Password" />
          <StyledPasswordInput name="password-confirm" label="Confirm Password" />

          <button type="submit">Submit</button>
        </Form>
      </Formik>
    </Base>
  );
}
