import axios from "axios";
import toCamelCase, { handleDjangoApiError, toSnakeCase } from "../helpers";

export interface Technology {
  id?: number;
  name: string;
  quadrant: string;
  ring: string;
  isNew: boolean;
  description: string;
}

const getTechnologies = async (): Promise<Technology[] | string> => {
  try {
    const response = await axios.get("technologies");
    return response.data.data.map((u: unknown) => toCamelCase(u));
  } catch (e) {
    return handleDjangoApiError(e);
  }
};
export default getTechnologies;

export const postTechnology = async (data: Technology | Technology[]): Promise<string> => {
  try {
    await axios.post("technologies", toSnakeCase(data));
    // eslint-disable-next-line no-alert
    return "rolou";
  } catch (e) {
    return handleDjangoApiError(e);
  }
};
