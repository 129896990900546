import React, { FunctionComponent } from "react";
import { ErrorMessage, useField } from "formik";
import { createStyles, Grid, Switch as MuiSwitch, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UiInput } from "./index";

export const useStyledSwitchStyles = makeStyles(() =>
  createStyles({
    label: {
      display: "inline-block",
      fontSize: "0.8rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden"
    },
    container: {
      alignItems: "center",
      justifyContent: "center",
      margin: 0
    }
  })
);

type SwitchProps = UiInput<boolean> & {
  left: string;
  right: string;
  tooltip?: string;
};

const StyledSwitch: FunctionComponent<SwitchProps> = (props) => {
  const classes = useStyledSwitchStyles();
  const { name, label, left, right, onChange } = props;
  const [, meta, helpers] = useField<typeof props.value>(name);

  const handleChangeSwitch = (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (typeof onChange === "function") {
      onChange(Boolean(checked));
    }
    helpers.setValue(Boolean(checked));
  };

  return (
    <>
      <Typography variant="h4">{label}</Typography>
      <Grid container spacing={1} className={classes.container}>
        <Typography variant="caption" component={Grid} item>
          {left}
        </Typography>
        <Grid item>
          <MuiSwitch color="primary" checked={Boolean(meta.value)} onChange={handleChangeSwitch} name={name} />
        </Grid>
        <Typography variant="caption" component={Grid} item>
          {right}
        </Typography>
      </Grid>
      {meta.error && (
        <Typography variant="caption" color="error">
          <ErrorMessage name={props.name} />
        </Typography>
      )}
    </>
  );
};

export default StyledSwitch;
