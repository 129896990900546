import React, { ReactNode } from "react";
import { IconButtonProps, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useIconButtonStyles } from "./index";
import useAlert from "../Dialog/Alert";

type CopyCodeButtonProps = IconButtonProps & {
  title: string;
  icon: ReactNode;
  variant?: "light" | "dark";
  toCopy: string;
};

export default function CopyCodeButton({ title, icon, variant, toCopy, ...props }: CopyCodeButtonProps): JSX.Element {
  const classes = useIconButtonStyles();

  const copiedAlert = useAlert({ message: "Codeausschnitt kopiert", severity: "success" });

  const onCopyText = () => {
    // setIsCopied(true);
    copiedAlert.show();
  };

  return (
    <>
      <Tooltip title={title}>
        <CopyToClipboard text={toCopy} onCopy={onCopyText}>
          <IconButton className={clsx(classes.iconButton, variant && classes[variant])} {...props}>
            {icon}
          </IconButton>
        </CopyToClipboard>
      </Tooltip>
      <copiedAlert.Component />
    </>
  );
}
