import React, { useState } from "react";
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from "@material-ui/core";
import { useField } from "formik";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import { UiInput } from "./index";
import { theme } from "../Theme";

export default function StyledPasswordInput({ name, value, label, onChange }: UiInput<string>): JSX.Element {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [, meta, helpers] = useField<typeof value>(name);
  const [showPassword, setShowPassword] = useState(false);

  const onChangePassword = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(ev.target.value);
    } else {
      helpers.setValue(ev.target.value);
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault();

  return (
    <FormControl fullWidth>
      <InputLabel style={{ color: meta.touched && meta.error ? theme.palette.error.main : undefined }} htmlFor={name}>
        {label}
      </InputLabel>
      <Input
        fullWidth
        id={`pass-${name}`}
        name={name}
        type={showPassword ? "text" : "password"}
        value={meta.value}
        error={meta.touched && meta.error !== undefined}
        onChange={onChangePassword}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {meta.touched && meta.error && (
        <Typography variant="caption" color="error">
          {meta.error}
        </Typography>
      )}
    </FormControl>
  );
}
