import React, { useEffect, useState } from "react";

import Base from "../../Base";
import getTechnologies, { Technology } from "./helpers";
import TechnologyTable from "./Table";

export default function Technologies(): JSX.Element {
  const [technologies, setTechnologies] = useState<Array<Technology>>([]);

  useEffect(() => {
    getTechnologies().then((r) => {
      if (typeof r === "string") {
        console.warn(r);
      } else {
        setTechnologies(r);
      }
    });
  }, []);

  return (
    <Base title="Technologien">
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <TechnologyTable technologies={technologies} />
    </Base>
  );
}
