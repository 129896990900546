import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import { Link as MuiLink } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import StyledTextInput from "../../Base/Inputs/TextInput";
import StyledPasswordInput from "../../Base/Inputs/PasswordInput";
import { handleDjangoApiError } from "../../secure/helpers";
import Bg1 from "./bg1.jpg";
import Bg2 from "./bg2.jpg";
import Bg3 from "./bg3.jpg";
import Logo from "../../Base/Logo";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <MuiLink color="inherit" href="https://material-ui.com/">
        KI.ASSIST
      </MuiLink>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const backgrounds = [Bg1, Bg2, Bg3];
const randomNum = Math.floor(Math.random() * backgrounds.length);
const chosenBg = backgrounds[randomNum];

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: `url(${chosenBg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    padding: theme.spacing(0, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)"
  },
  logo: {
    margin: theme.spacing(1),
    "& svg": {
      height: 60
    }
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

interface SignInValues {
  email: string;
  password: string;
}

export default function SignIn(): JSX.Element {
  const classes = useStyles();

  const [redirect, setRedirect] = React.useState<boolean>(false);
  const [submissionError, setSubmissionError] = React.useState<string>("");

  const getSessionCookie = async () => {
    const response = await axios.get("user");
    if (response.status === 200) {
      setRedirect(true);
    }
  };
  useEffect(() => {
    getSessionCookie();
  }, []);

  return redirect ? (
    <Redirect to="/technologies" />
  ) : (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <Formik<SignInValues>
            initialValues={{
              email: "",
              password: ""
            }}
            validationSchema={Yup.object({
              email: Yup.string().email("Invalid email address").required("Required"),
              password: Yup.string().required("Required")
            })}
            onSubmit={async (values) => {
              try {
                await axios.post("login", {
                  email: values.email,
                  password: values.password
                });
                setRedirect(true);
              } catch (e) {
                setSubmissionError(handleDjangoApiError(e));
              }
            }}
          >
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledTextInput name="email" label="E-Mail" />
                </Grid>
                <Grid item xs={12}>
                  <StyledPasswordInput name="password" label="Passwort" />
                  {submissionError !== "" && (
                    <Typography variant="caption" color="error">
                      {submissionError}
                    </Typography>
                  )}
                  <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                    Einloggen
                  </Button>
                </Grid>
                <Grid item xs>
                  <Link to="/">Forgot password?</Link>
                </Grid>
              </Grid>
              <Box mt={6}>
                <Copyright />
              </Box>
            </Form>
          </Formik>
        </div>
      </Grid>
    </Grid>
  );
}
