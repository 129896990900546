import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { createOption, getRoles, getUser } from "./helpers";
import StyledSelect from "../../Base/Inputs/SelectInput";
import StyledTextInput from "../../Base/Inputs/TextInput";
import { handleDjangoApiError } from "../helpers";
import StyledIconButton from "../../Base/IconButton";
import { Role } from "./Roles";
import { TeamMember } from "./index";
import useAlert from "../../Base/Dialog/Alert";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    paddingBottom: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

interface EditMemberValues {
  firstName: string;
  lastName: string;
  email: string;
  role: number;
}

interface EditMemberProps {
  id: number;
  setOpen: (open: boolean) => void;
}

export default function EditMember({ id, setOpen }: EditMemberProps): JSX.Element {
  const classes = useStyles();

  const [member, setMember] = useState<TeamMember | undefined>(undefined);
  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    getUser(id).then((r) => {
      if (typeof r === "string") {
        setSubmissionError(r);
      } else {
        setMember(r);
      }
    });
    getRoles().then((r) => {
      if (typeof r === "string") {
        setSubmissionError(r);
      } else {
        setRoles(r);
      }
    });
  }, [id]);

  const successAlert = useAlert({ message: "Rolou!", severity: "success" });
  const errorAlert = useAlert({ message: submissionError || "", severity: "error" });

  return member ? (
    <>
      <Formik<EditMemberValues>
        initialValues={{
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
          role: member.role.id
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
          lastName: Yup.string().max(20, "Must be 20 characters or less").required("Required"),
          email: Yup.string().email("Invalid email address").required("Required")
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            await axios.put(`users/${id}`, {
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.email,
              role_id: values.role
            });
            successAlert.show();
            setSubmitting(false);
            setSubmissionError(undefined);
            setOpen(false);
          } catch (e) {
            setSubmitting(false);
            setSubmissionError(handleDjangoApiError(e));
          }
        }}
      >
        {() => (
          <Form className={classes.form}>
            <Grid container spacing={2} justify="center">
              <Grid item xs={12} sm={6} lg={2}>
                <StyledTextInput name="firstName" label="Vorname" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <StyledTextInput name="lastName" label="Nachname" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <StyledTextInput name="email" label="E-Mail" size="small" />
              </Grid>
              <Grid item xs={11} sm={5} lg={2}>
                <StyledSelect label="Role" name="role" items={roles.map((r) => createOption(r.id, r.name))} />
              </Grid>
              <Grid item xs={1}>
                <StyledIconButton type="submit" title="Änderungen speichern" icon={<SaveIcon />} color="primary" />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {submissionError && errorAlert.show()}
      <successAlert.Component />
      <errorAlert.Component />
    </>
  ) : (
    // TODO use something nicer here
    <>Placeholder</>
  );
}
