import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import clsx from "clsx";
import { Collapse, IconButton, Tooltip } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import { Add } from "@material-ui/icons";
import StyledIconButton from "../../../Base/IconButton";
import Title from "../../Dashboard/Title";
import { getRoles } from "../helpers";
import StyledDialog from "../../../Base/Dialog";
import RoleForm from "./RoleForm";
import { useRowStyles } from "../index";
import useWarning from "../../../Base/Dialog/Warning";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2)
  },
  tableBody: {
    "& .MuiTableBody-root .MuiTableRow-root:nth-child(4n - 3),  .MuiTableBody-root .MuiTableRow-root:nth-child(4n - 2)": {
      backgroundColor: theme.palette.action.hover
    }
  },
  tableHead: {
    "& .MuiTableCell-head": {
      color: theme.palette.primary.main,
      padding: theme.spacing(1, 2)
    }
  },
  dialogTitle: {
    color: theme.palette.primary.main
  },
  userRow: {
    "& .MuiTableCell-body": {
      transition: theme.transitions.create("color")
    },
    "& > *": {
      borderBottom: "unset"
    }
  },
  editing: {
    "& .MuiTableCell-body": {
      color: theme.palette.grey[400]
    }
  },
  permissionName: {
    display: "inline-block"
  }
}));

export interface Permission {
  id: number;
  name: string;
}

export interface Role {
  id: number;
  name: string;
  permissions: Array<Permission>;
}

export default function Roles(): JSX.Element {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    getRoles().then((r) => {
      if (typeof r === "string") {
        console.warn(r);
      } else {
        setRoles(r);
      }
    });
  }, []);

  return (
    <>
      <div className={classes.header}>
        <Title>Role</Title>
        <StyledIconButton title="Neue Role hinzufügen" variant="dark" onClick={() => setOpen(true)} icon={<Add />} />
        <StyledDialog open={open} onClose={() => setOpen(false)} title="Role erstellen">
          <RoleForm />
        </StyledDialog>
      </div>

      <Table size="small" className={classes.tableBody}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center" width={100}>
              <LockOpenIcon color="action" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roles && roles.map((role) => <RoleTableRow key={role.id} role={role} roles={roles} setRoles={setRoles} />)}
        </TableBody>
      </Table>
    </>
  );
}

interface RowProps {
  role: Role;
  // TODO find a better solution (store?)
  setRoles: (roles: Role[]) => void;
  roles: Array<Role>;
}

function RoleTableRow({ role, roles, setRoles }: RowProps): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const warning = useWarning({
    message: `Möchtest Du diese Role wirklich löschen? Du wirst sie nicht mehr abrufen können.`,
    callback: async () => {
      await axios.delete(`roles/${role.id}`);
      setRoles(roles.filter((r: Role) => r.id !== role.id));
    }
  });

  const editBtn = (
    <Tooltip title={`${role.name} bearbeiten`}>
      <IconButton size="small" onClick={() => setOpen(!open)}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
  const cancelBtn = (
    <Tooltip title="Abbrechen">
      <IconButton size="small" onClick={() => setOpen(!open)}>
        <CancelIcon />
      </IconButton>
    </Tooltip>
  );
  const deleteBtn = (
    <Tooltip title={`${role.name} löschen`}>
      <IconButton size="small" onClick={() => warning.show()}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <TableRow key={role.name} className={clsx(classes.userRow, open && classes.editing)}>
        <TableCell>{role.name}</TableCell>
        <TableCell align="right">
          {open ? cancelBtn : editBtn}
          {deleteBtn}
        </TableCell>
      </TableRow>
      <TableRow key={`${role.name}-edit`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RoleForm role={role} setOpen={setOpen} />
          </Collapse>
        </TableCell>
      </TableRow>
      <warning.Dialog />
    </>
  );
}
