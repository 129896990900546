import React from "react";
import { Link as ReactRouterLink, LinkProps as RouterLinkProps, useRouteMatch } from "react-router-dom";

import { Tab as MuiTab, TabProps as MuiTabProps } from "@material-ui/core";

export const TabLink: React.FC<Omit<RouterLinkProps, "to"> & MuiTabProps & { to?: RouterLinkProps["to"] }> = ({
  to,
  value,
  ...props
}) => {
  return <MuiTab component={RouterLink} to={to ?? value} value={value} {...props} />;
};

const MuiRouterLink: React.FC<Omit<RouterLinkProps, "innerRef" | "ref">> = (props, ref) => (
  <ReactRouterLink innerRef={ref} {...props} />
);

// Todo any
export const RouterLink = React.forwardRef(MuiRouterLink as any);

export const useTabsWithRouter = (basePath: string, routes: string[], defaultRoute: string) => {
  const paths = routes.map((r) => `${basePath}${r}`);

  const match = useRouteMatch(paths);
  return {
    tabsValue: match?.path ?? `${basePath}${defaultRoute}`,
    paths,
    basePath
  };
};
