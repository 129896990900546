import React from "react";
import { useField } from "formik";
import { createStyles, InputAdornment } from "@material-ui/core";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import Brightness1OutlinedIcon from "@material-ui/icons/Brightness1Outlined";
import ColorPicker from "material-ui-color-picker";
import { makeStyles } from "@material-ui/core/styles";
import { UiInput } from "./index";

const useStyles = makeStyles((theme) =>
  createStyles({
    colorDisplay: {
      paddingRight: theme.spacing(1)
    }
  })
);

export function isValidColor(c: string): boolean {
  const s = new Option().style;
  s.color = c.toLowerCase();
  const isValidColorName = String(s.color) === c.toLowerCase();
  const isValidHexRgbaOrHsla = new RegExp(
    /^(#[\da-f]{3}|#[\da-f]{6}|rgba\(((\d{1,2}|1\d\d|2([0-4]\d|5[0-5]))\s*,\s*){2}((\d{1,2}|1\d\d|2([0-4]\d|5[0-5]))\s*)(,\s*(0\.\d+|1))\)|hsla\(\s*((\d{1,2}|[1-2]\d{2}|3([0-5]\d|60)))\s*,\s*((\d{1,2}|100)\s*%)\s*,\s*((\d{1,2}|100)\s*%)(,\s*(0\.\d+|1))\)|rgb\(((\d{1,2}|1\d\d|2([0-4]\d|5[0-5]))\s*,\s*){2}((\d{1,2}|1\d\d|2([0-4]\d|5[0-5]))\s*)|hsl\(\s*((\d{1,2}|[1-2]\d{2}|3([0-5]\d|60)))\s*,\s*((\d{1,2}|100)\s*%)\s*,\s*((\d{1,2}|100)\s*%)\))$/i
  ).test(c);
  return isValidColorName || isValidHexRgbaOrHsla;
}

function isInvisibleColor(c: string): boolean {
  return (
    c === "white" ||
    c === "transparent" ||
    c.endsWith("0)") ||
    c.startsWith("rgba(255, 255, 255, ") ||
    c?.toUpperCase() === "#FFF" ||
    c?.toUpperCase() === "#FFFFFF"
  );
}

export default function ColorInput({ name, label }: UiInput<string>): JSX.Element {
  const classes = useStyles();
  const [, meta, helpers] = useField<string>(name);

  const onChangeColor = (c: string) => {
    helpers.setValue(c);
  };
  const color = meta.value && meta.value !== "" && isValidColor(meta.value) ? meta.value : "transparent";

  const colorDisplay = (
    <InputAdornment position="end" className={classes.colorDisplay}>
      {isInvisibleColor(color) ? (
        <Brightness1OutlinedIcon style={{ color: "#DDD" }} />
      ) : (
        <Brightness1Icon style={{ color }} />
      )}
    </InputAdornment>
  );

  return (
    <ColorPicker
      fullWidth
      id={`color-picker-${name}`}
      label={label}
      value={meta.value}
      onChange={onChangeColor}
      error={meta.touched && meta.error !== undefined}
      helperText={(meta.touched && meta.error) || ""}
      autoComplete="off"
      InputLabelProps={{
        shrink: !!color && meta.value !== ""
      }}
      InputProps={{
        value: meta.value,
        endAdornment: colorDisplay
      }}
    />
  );
}
