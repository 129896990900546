import React, { FunctionComponent, useState } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ErrorMessage, useField } from "formik";
import htmlToDraft from "html-to-draftjs";
import { makeStyles } from "@material-ui/core/styles";
import draftToHtml from "draftjs-to-html";
import { Typography } from "@material-ui/core";
import { UiInput } from "../index";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    transition: theme.transitions.create("border"),
    borderBottom: `1px solid ${theme.palette.text.secondary}`,

    "&:focus-within": {
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  },
  toolbar: {
    position: "sticky",
    top: 0,
    height: 0,
    padding: 0,
    marginBottom: 0,
    background: theme.palette.background.default,
    zIndex: 2,
    transition: theme.transitions.create("height"),
    "&[aria-hidden=false], &:focus-within": {
      padding: "6px 5px 0",
      marginBottom: 5,
      height: 80
    },
    "&[aria-hidden=true]:not(:focus-within)": {
      padding: 0,
      marginBottom: 0,
      height: 0
    },
    "& .rdw-inline-wrapper": {
      marginBottom: 0
    }
  },
  viewModeToolBar: {
    height: 0,
    display: "none!important"
  },
  editor: {
    minHeight: 120,
    padding: theme.spacing(2, 0),
    "& .DraftEditor-root": {
      marginTop: theme.spacing(-2)
    }
  }
}));

interface TextEditorProps extends UiInput<string> {
  onChangeHtml?: (html: string) => void;
  toolTip?: string;
}

const StyledTextEditor: FunctionComponent<TextEditorProps> = ({ name, label, onChangeHtml, value }) => {
  const classes = useStyles();
  const [, meta, helpers] = useField<typeof value>(name);

  // eslint-disable-next-line consistent-return
  const getDefaultState = () => {
    if (meta.value) {
      const contentBlock = htmlToDraft(meta.value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
      }
    } else {
      return () => EditorState.createEmpty();
    }
  };

  const [editorState, setEditorState] = useState(getDefaultState());

  const handleChangeEditorState = (state: EditorState) => {
    setEditorState(state);
    const html = draftToHtml(convertToRaw(state.getCurrentContent()));
    helpers.setValue(
      html === "<p></p>\n" || html === "<p></p>" ? "" : draftToHtml(convertToRaw(state.getCurrentContent()))
    );
    // helpers.setValue(html);
    if (typeof onChangeHtml === "function") {
      onChangeHtml(html);
    }
  };

  const borderColor = meta.touched && meta.error !== undefined ? "red" : undefined;

  return (
    <div>
      <Typography variant="overline">{label}</Typography>
      <Editor
        toolbarOnFocus
        wrapperClassName={classes.wrapper}
        wrapperStyle={{ borderBottomColor: borderColor }}
        toolbarClassName={classes.toolbar}
        editorClassName={classes.editor}
        editorState={editorState}
        onEditorStateChange={handleChangeEditorState}
        toolbar={{
          options: ["link", "inline", "list", "emoji", "textAlign"]
        }}
      />
      {meta.error && (
        <Typography variant="caption" color="error">
          <ErrorMessage name={name} />
        </Typography>
      )}
    </div>
  );
};

export default StyledTextEditor;
