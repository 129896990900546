import React from "react";
import { Checkbox, CheckboxProps, FormControlLabel } from "@material-ui/core";
import { useField } from "formik";
import { UiInput } from "./index";

type StyledCheckboxProps = UiInput<boolean> & CheckboxProps;

export default function StyledCheckbox({ name, label, onChange, value, ...props }: StyledCheckboxProps): JSX.Element {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you
  const [, meta, helpers] = useField<typeof value>(name);

  const onChangeCheckBox = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(ev.target.checked);
    } else {
      helpers.setValue(ev.target.checked);
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={meta.value} onChange={onChangeCheckBox} name={name} {...props} />}
      label={label}
    />
  );
}
