import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import StyledTextInput from "../../Base/Inputs/TextInput";
import StyledPasswordInput from "../../Base/Inputs/PasswordInput";
import StyledSelect from "../../Base/Inputs/SelectInput";
import { handleDjangoApiError } from "../helpers";
import { createOption, getRoles } from "./helpers";
import { Role } from "./Roles";
import useAlert from "../../Base/Dialog/Alert";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

interface CreateUserValues {
  firstName: string;
  lastName: string;
  email: string;
  role: number;
  password: string;
  passwordConfirm: string;
}

export default function CreateMember(): JSX.Element {
  const classes = useStyles();

  const [roles, setRoles] = useState<Role[]>([]);
  useEffect(() => {
    getRoles().then((r) => {
      if (typeof r === "string") {
        console.warn(r);
      } else {
        setRoles(r);
      }
    });
  }, []);

  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);

  const successAlert = useAlert({ message: "Rolou!", severity: "success" });
  const errorAlert = useAlert({ message: submissionError || "", severity: "error" });

  return (
    <>
      <Formik<CreateUserValues>
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          role: 3,
          password: "",
          passwordConfirm: ""
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
          lastName: Yup.string().max(20, "Must be 20 characters or less").required("Required"),
          email: Yup.string().email("Invalid email address").required("Required"),
          password: Yup.string().required("Required"),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Required")
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          try {
            await axios.post("register", {
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.email,
              password: values.password,
              password_confirm: values.passwordConfirm,
              role: values.role
            });
            resetForm();
            successAlert.show();
            setSubmissionError(undefined);
          } catch (e) {
            setSubmissionError(handleDjangoApiError(e));
          }
        }}
      >
        {(props) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledSelect
                  required
                  label="Role"
                  value={props.values.role}
                  name="role"
                  items={roles.map((r) => createOption(r.id, r.name))}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextInput name="firstName" label="Vorname" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextInput name="lastName" label="Nachname" />
              </Grid>
              <Grid item xs={12}>
                <StyledTextInput name="email" label="E-Mail" />
              </Grid>
              <Grid item xs={12} className="olhaEuAquiBitch3">
                <StyledPasswordInput name="password" label="Passwort" />
              </Grid>
              <Grid item xs={12}>
                <StyledPasswordInput name="passwordConfirm" label="Passwort bestätigen" />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                  {props.isSubmitting ? "Loading..." : "Speichern"}
                </Button>
                {submissionError && errorAlert.show()}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {submissionError && errorAlert.show()}
      <successAlert.Component />
      <errorAlert.Component />
    </>
  );
}
