import axios from "axios";
import toCamelCase, { handleDjangoApiError } from "../helpers";
import { Permission, Role } from "./Roles";
import { TeamMember } from "./index";

const getUsers = async (): Promise<TeamMember[] | string> => {
  try {
    const response = await axios.get("users");
    return response.data.data.map((u: TeamMember) => toCamelCase(u));
  } catch (e) {
    return handleDjangoApiError(e);
  }
};
export default getUsers;

export const getUser = async (id: number): Promise<TeamMember | string> => {
  try {
    const response = await axios.get(`users/${id}`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return toCamelCase<TeamMember>(response.data.data);
  } catch (e) {
    return handleDjangoApiError(e);
  }
};

export const getRoles = async (): Promise<Role[] | string> => {
  try {
    const response = await axios.get("roles");
    return response.data.data.map((r: Role) => toCamelCase(r));
  } catch (e) {
    return handleDjangoApiError(e);
  }
};

export interface SelectOption {
  value: string | number;
  label: string;
}

export const createOption = (value: number | string, label: string): SelectOption => {
  return { value, label };
};

export const getPermissions = async (): Promise<Permission[] | string> => {
  try {
    const response = await axios.get("permissions");
    return response.data.data.map((p: Permission) => toCamelCase(p));
  } catch (e) {
    return handleDjangoApiError(e);
  }
};
