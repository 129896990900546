import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import StyledTextInput from "../../Base/Inputs/TextInput";
import { handleDjangoApiError } from "../helpers";
import { Technology } from "./helpers";
import StyledCheckbox from "../../Base/Inputs/Checkbox";
import StyledTextEditor from "../../Base/Inputs/TextEditor";
import useAlert from "../../Base/Dialog/Alert";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    flexGrow: 1,
    paddingBottom: theme.spacing(3)
  },
  formGroupLabel: {
    paddingTop: theme.spacing(1)
  }
}));

export const technologyValidationSchema = {
  name: Yup.string().max(100, "Muss 100 Zeichen oder weniger haben").required("Erforderlich"),
  quadrant: Yup.string().max(100, "Muss 100 Zeichen oder weniger haben").required("Erforderlich"),
  ring: Yup.string().max(100, "Muss 100 Zeichen oder weniger haben").required("Erforderlich"),
  description: Yup.string()
    .test("Max", "Muss 1.000 Zeichen oder weniger haben", (v = "") => v.replace(/<\/?[^>]+(>|$)/g, "").length < 1000)
    .required("Erforderlich")
};

interface TechnologyFormProps {
  technology?: Technology;
  setOpen?: (open: boolean) => void;
}

export default function TechnologyForm({ technology, setOpen }: TechnologyFormProps): JSX.Element {
  const classes = useStyles();
  const [apiError, setApiError] = useState<string | undefined>(undefined);

  const successAlert = useAlert({ message: "Rolou!", severity: "success" });
  const errorAlert = useAlert({ message: apiError || "", severity: "error" });

  const history = useHistory();

  return (
    <>
      <Formik<Technology>
        initialValues={{
          id: technology?.id || undefined,
          name: technology?.name || "",
          quadrant: technology?.quadrant || "",
          ring: technology?.ring || "",
          isNew: technology?.isNew || false,
          description: technology?.description || ""
        }}
        validationSchema={Yup.object(technologyValidationSchema)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const data = {
            name: values.name,
            quadrant: values.quadrant,
            ring: values.ring,
            is_new: values.isNew,
            description: values.description
          };
          try {
            if (technology) {
              await axios.put(`technologies/${technology.id}`, data);
            } else {
              // console.log("so um!");
              await axios.post("technologies", data);
            }
            successAlert.show();
            setApiError(undefined);
            resetForm();
            if (setOpen) setOpen(false);
            history.go(0);
          } catch (e) {
            setApiError(handleDjangoApiError(e));
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <StyledTextInput name="name" label="Name" />
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextInput name="quadrant" label="Gruppe" />
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextInput name="ring" label="Kreis" />
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledCheckbox name="isNew" label="Ist diese Technologie neu?" />
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextEditor name="description" label="Beschreibung" />
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" fullWidth variant="contained" color="primary">
                  {/* TODO set time delay to show loading symbol */}
                  {isSubmitting ? "Loading..." : "Speichern"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {apiError && errorAlert.show()}
      <successAlert.Component />
      <errorAlert.Component />
    </>
  );
}
TechnologyForm.defaultProps = {
  technology: undefined,
  setOpen: undefined
} as Partial<TechnologyFormProps>;
