import React, { PropsWithoutRef, useEffect, useState } from "react";
import axios from "axios";
import { Radar } from "ki-assist-radar";
import { handleDjangoApiError } from "../../../secure/helpers";

interface PublicChartProps {
  match: PropsWithoutRef<any>;
}

export default function PublicChart({ match }: PublicChartProps): JSX.Element {
  const { id } = match.params;
  const [apiError, setApiError] = useState<string | undefined>(undefined);

  const getChart = async (radarId: string) => {
    try {
      return await axios.get(`charts/public/radar/${radarId}`);
    } catch (e) {
      return handleDjangoApiError(e);
    }
  };

  useEffect(() => {
    getChart(id).then((r) => {
      if (typeof r === "string") {
        setApiError(r);
      } else {
        const { config, data } = r.data;
        const viewsConf = [
          {
            id: "ClassicView",
            name: "Classic",
            icon: "ICON_STAR",
            rotation: 1,
            fields: {
              name: "name",
              section: "quadrant",
              ring: "ring",
              text: "description",
              class: "isNew",
              color: "quadrant"
            },
            section_order: [
              "1. Wahrnehmungs- und Kommunikationsunterstützung",
              "2. Interaktionsunterstützung",
              "3. Navigations-/Orientierungsunterstützung",
              "4. Konkrete Arbeitsunterstützung",
              "5. Lernunterstützung",
              "6. Physische Unterstützung",
              "7. Psychische Unterstützung"
            ],
            section_names: {
              "1. Wahrnehmungs- und Kommunikationsunterstützung": "Wahrnehmungs- und Kommunikations(--)unterstützung",
              "2. Interaktionsunterstützung": "Interaktions(--)unterstützung",
              "3. Navigations-/Orientierungsunterstützung": "Navigations- und Orientierungs(--)unterstützung",
              "4. Konkrete Arbeitsunterstützung": "Konkrete Arbeits(prozess)(--)unterstützung",
              "5. Lernunterstützung": "Lernunter(--)stützung",
              "6. Physische Unterstützung": "Physische Unterstützung",
              "7. Psychische Unterstützung": "Psychische Unterstützung"
            },
            ring_order: "AUTO",
            ring_names: {
              "Fertiges Produkt": "Fertiges Produkt",
              "Forschungs- und Entwicklungsprojekt": "Forschungs- und Entwicklungsprojekt",
              "In der Entwicklung": "In der Entwicklung"
              // Körperbehinderung: "Körperbehinderung"
              // "Chronische Erkrankung": "Chronische Erkrankung"
            },
            colors: {
              "languages-and-frameworks": "#da4e4e",
              "1. Wahrnehmungs- und Kommunikationsunterstützung": config.gruppe_1,
              "2. Interaktionsunterstützung": config.gruppe_2,
              "3. Navigations-/Orientierungsunterstützung": config.gruppe_3,
              "4. Konkrete Arbeitsunterstützung": config.gruppe_4,
              "5. Lernunterstützung": config.gruppe_5,
              "6. Physische Unterstützung": config.gruppe_6,
              "7. Psychische Unterstützung": config.gruppe_7
            },
            class_order: ["TRUE", "FALSE"],
            class_names: {
              TRUE: "New or moved",
              FALSE: "No change"
            },
            class_symbols: {
              TRUE: "SYMBOL_TRIANGLE",
              FALSE: "SYMBOL_CIRCLE"
            },
            radar_unselected_items_color: "#bababaff",
            radar_unselected_filtered_items_color: "#000000ff"
          }
        ];
        // console.log(viewsConf);
        // console.log(JSON.stringify(data));
        Radar.Init();

        // console.log(JSON.stringify({ ...config, views: viewsConf }));
        setTimeout(() => Radar.getById(`RADAR-${id}}`).setConfig({ ...config, views: viewsConf }), 1500);
        setTimeout(() => Radar.getById(`RADAR-${id}}`).setData(data), 2000);
      }
    });
  }, [id]);

  return (
    <div className="radar" id={`RADAR-${id}}`} data-src="none">
      {apiError || "Fehler beim Laden des Radars"}
    </div>
  );
}
