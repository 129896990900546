import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core/styles";

export const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    }
  })
);

export default useTableStyles;

export const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 2),
      minHeight: 120,
      position: "sticky",
      top: -8,
      zIndex: 2
    },
    highlight: {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85)
    },
    title: {
      flex: "1 1 100%"
    },
    singleActionButton: {
      width: 56,
      height: 56,
      marginRight: theme.spacing(2),
      boxShadow: theme.shadows[5]
    },
    speedDial: {
      width: 56,
      position: "relative",
      marginRight: theme.spacing(2),
      transition: theme.transitions.create("width"),

      "& > button": {
        position: "absolute"
      },
      "& > div.MuiSpeedDial-actions": {
        marginLeft: theme.spacing(2)
      }
    },
    openSpeedDial: {
      width: 180
    },
    dropZone: {
      minHeight: "inherit",
      color: theme.palette.secondary.light,

      "& .MuiDropzoneArea-icon": {
        color: theme.palette.secondary.light
      },
      "& .MuiDropzoneArea-textContainer": {
        padding: theme.spacing(2),
        width: "100%"
      },
      "& .MuiDropzoneArea-text": {
        display: "inline",
        verticalAlign: "middle",
        paddingRight: theme.spacing(2)
      }
    }
  })
);
