import React, { ChangeEvent } from "react";
import { createStyles, FormControl, InputLabel, MenuItem, Select, SelectProps, Typography } from "@material-ui/core";
import { ErrorMessage, useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120
    },
    label: {
      "&.MuiInputLabel-shrink + .MuiInput-formControl": {
        marginTop: 13
      }
    }
  })
);

interface SelectItems {
  value: string | number;
  label: string;
}

type StyledSelectProps = SelectProps & {
  name: string;
  label: string;
  value?: string | number;
  items: Array<SelectItems>;
  onChange?: (e: ChangeEvent<{ name?: string; value: string | number }>) => void;
};

export default function StyledSelect({
  name,
  value,
  label,
  onChange,
  items,
  ...props
}: StyledSelectProps): JSX.Element {
  const classes = useStyles();
  const [field, meta, helpers] = useField<typeof value>(name);

  field.onChange = (e: ChangeEvent<{ name?: string; value: string | number }>) => {
    const { value: val } = e.target;
    if (onChange) {
      onChange(val as any);
      // console.log("change", meta.value);
    }
    helpers.setValue(val);
  };

  return (
    <FormControl fullWidth className={classes.formControl}>
      <InputLabel htmlFor={`select-${name}`} className={classes.label}>
        {label || name}
      </InputLabel>
      <Select
        fullWidth
        name={name}
        id={`select-${name}`}
        value={value || meta.value || ""}
        onChange={field.onChange}
        {...props}
      >
        {items.map((i) => (
          <MenuItem key={`option-${i.value}`} value={i.value}>
            {i.label}
          </MenuItem>
        ))}
      </Select>
      {meta.error && (
        <Typography variant="caption" color="error">
          <ErrorMessage name={name} />
        </Typography>
      )}
    </FormControl>
  );
}
