import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { useField } from "formik";
import { UiInput } from "./index";

type StyledTextInputProps = UiInput<string> & TextFieldProps;

export default function StyledTextInput({ name, value, label, onChange, ...props }: StyledTextInputProps): JSX.Element {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [, meta, helpers] = useField<typeof value>(name);

  const onChangeTextField = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(ev.target.value);
    } else {
      helpers.setValue(ev.target.value);
    }
  };

  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      value={meta.value}
      onChange={onChangeTextField}
      error={meta.touched && meta.error !== undefined}
      helperText={(meta.touched && meta.error) || ""}
      {...props}
    />
  );
}
