import React, { useEffect, useState } from "react";
import { List, Popover } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import ListItem from "@material-ui/core/ListItem";
import { Redirect } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import axios from "axios";
import NavigationItem from "../Navigation/NavigationItem";
import { handleDjangoApiError } from "../../secure/helpers";

const useStyles = makeStyles((theme) => ({
  popOverList: {
    minWidth: 100,
    "& .MuiListItemIcon-root": {
      minWidth: 30
    },
    "& .MuiTypography-root": {
      color: theme.palette.grey["500"],
      fontSize: "0.95em"
    }
  }
}));

export default function Settings(): JSX.Element {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [logout, setLogout] = useState(false);
  const handleLogout = async () => {
    try {
      await axios.post("logout", {});
      setLogout(true);
    } catch (e) {
      console.warn(e);
    }
  };

  // TODO use store for that
  const [userId, setUserId] = useState<undefined | number>(undefined);
  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get("user");
        return response.data.data.id;
      } catch (e) {
        handleDjangoApiError(e);
        return undefined;
      }
    }
    getUser().then((r) => setUserId(r));
  }, []);

  const settingsPopOver = (
    <List className={classes.popOverList}>
      <NavigationItem name="Profil" url={`/profile/${userId}`} icon={<AssignmentIndOutlinedIcon />} />
      <NavigationItem name="Team" url="/team" icon={<PeopleOutlineOutlinedIcon />} />
      <li>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Abmelden" />
        </ListItem>
      </li>
    </List>
  );

  return logout ? (
    <Redirect to="/" />
  ) : (
    <>
      <IconButton color="inherit" onClick={handleOpen}>
        <SettingsIcon />
      </IconButton>
      <Popover
        id={open ? "settings-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {settingsPopOver}
      </Popover>
    </>
  );
}
