import React, { PropsWithoutRef, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { TeamMember } from "../Team";
import { Role } from "../Team/Roles";
import { createOption, getRoles, getUser } from "../Team/helpers";
import { handleDjangoApiError } from "../helpers";
import StyledTextInput from "../../Base/Inputs/TextInput";
import StyledSelect from "../../Base/Inputs/SelectInput";
import Base from "../../Base";
import StyledPaper from "../../Base/Paper";
import Title from "../Dashboard/Title";
import useAlert from "../../Base/Dialog/Alert";
import StyledPasswordInput from "../../Base/Inputs/PasswordInput";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    paddingBottom: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

interface ProfileInfoValues {
  firstName: string;
  lastName: string;
  email: string;
  role: number;
}

interface ProfilePassValues {
  password: string;
  passwordConfirm: string;
}

interface UpdateUserProps {
  match: PropsWithoutRef<never>;
}

export default function Profile({ match }: UpdateUserProps): JSX.Element {
  const { id } = match.params;
  const classes = useStyles();

  const [user, setUser] = useState<TeamMember | undefined>(undefined);
  const [infoError, setInfoError] = useState<string | undefined>(undefined);
  const [passError, setPassError] = useState<string | undefined>(undefined);
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    getUser(id).then((r) => {
      if (typeof r === "string") {
        setInfoError(r);
      } else {
        setUser(r);
      }
    });
    getRoles().then((r) => {
      if (typeof r === "string") {
        setInfoError(r);
      } else {
        setRoles(r);
      }
    });
  }, [id]);

  const successAlert = useAlert({ message: "Änderungen gespeichert", severity: "success" });
  const infoErrorAlert = useAlert({ message: infoError || "", severity: "error" });
  const passErrorAlert = useAlert({ message: passError || "", severity: "error" });

  const editUserInfo = user && (
    <>
      <Formik<ProfileInfoValues>
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          role: user.role.id
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
          lastName: Yup.string().max(20, "Must be 20 characters or less").required("Required"),
          email: Yup.string().email("Invalid email address").required("Required")
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            await axios.put("users/info", {
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.email,
              role_id: values.role
            });
            successAlert.show();
            setSubmitting(false);
            setInfoError(undefined);
          } catch (e) {
            setSubmitting(false);
            setInfoError(handleDjangoApiError(e));
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form}>
            <StyledPaper>
              <Title>Profil bearbeiten</Title>
              <Grid container spacing={3} justify="center">
                <Grid item xs={12}>
                  <StyledTextInput name="firstName" label="Vorname" size="small" />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextInput name="lastName" label="Nachname" size="small" />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextInput name="email" label="E-Mail" size="small" />
                </Grid>
                <Grid item xs={12}>
                  <StyledSelect
                    label="Role"
                    name="role"
                    defaultValue={1}
                    items={roles.map((r) => createOption(r.id, r.name))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" fullWidth variant="contained" color="primary">
                    {/* TODO set time delay to show loading symbol */}
                    {isSubmitting ? "Loading..." : "Speichern"}
                  </Button>
                </Grid>
                {infoError && infoErrorAlert.show()}
              </Grid>
            </StyledPaper>
          </Form>
        )}
      </Formik>
      <successAlert.Component />
      <infoErrorAlert.Component />
    </>
  );

  const editUserPass = user && (
    <>
      <Formik<ProfilePassValues>
        initialValues={{
          password: "",
          passwordConfirm: ""
        }}
        validationSchema={Yup.object({
          password: Yup.string().required("Required"),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Required")
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            await axios.put("users/password", {
              password: values.password,
              password_confirm: values.passwordConfirm
            });
            successAlert.show();
            setSubmitting(false);
            setPassError(undefined);
          } catch (e) {
            setSubmitting(false);
            setPassError(handleDjangoApiError(e));
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form}>
            <StyledPaper>
              <Title>Passwort ändern</Title>
              <Grid container spacing={3} justify="center">
                <Grid item xs={12}>
                  <StyledPasswordInput name="password" label="Passwort" />
                </Grid>
                <Grid item xs={12}>
                  <StyledPasswordInput name="passwordConfirm" label="Passwort bestätigen" />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" fullWidth variant="contained" color="primary">
                    {/* TODO set time delay to show loading symbol */}
                    {isSubmitting ? "Loading..." : "Speichern"}
                  </Button>
                </Grid>
                {passError && passErrorAlert.show()}
              </Grid>
            </StyledPaper>
          </Form>
        )}
      </Formik>
      <successAlert.Component />
      <passErrorAlert.Component />
    </>
  );

  return (
    <Base title="Profil">
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          {editUserInfo}
        </Grid>
        <Grid item xs={12} lg={6}>
          {editUserPass}
        </Grid>
      </Grid>
    </Base>
  );
}
