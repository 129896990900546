import React, { PropsWithChildren, ReactNode } from "react";
import { Avatar, Grid, GridProps, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 3)
  },
  stepTitle: {
    "& .MuiAvatar-root": {
      width: 55,
      height: 55,
      float: "left",
      marginRight: theme.spacing(2),
      fontWeight: "bolder",
      fontSize: 36,
      transform: "translateY(-15%)"
    }
  }
}));

// ROW BREAK

export const GridRowBreak = () => <div style={{ flexBasis: "100%", height: 0 }} />;

// MAIN CONTAINER

export const MainGridContainer = ({ children, noPadding = false, ...props }: PropsWithChildren<any>) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.container, props.className && props.className)}
      style={{ padding: noPadding ? 0 : undefined }}
    >
      {children}
    </Grid>
  );
};

// TITLE

interface TitleGridProps {
  prefix: string | number;
  title: string;
}

export const TitleGrid = ({ prefix, title }: TitleGridProps) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.stepTitle}>
      <Avatar>{prefix}</Avatar>
      <Typography variant="h2">{title}</Typography>
    </Grid>
  );
};

// SUBTITLE

interface SubTitleGridProps extends GridProps {
  subtitle: string | ReactNode;
}

export const SubTitleGrid = (props: SubTitleGridProps) => (
  <Grid item xs={12} style={{ marginBottom: -8, marginTop: 16, ...props.style }} {...props}>
    <Typography variant="h3">{props.subtitle}</Typography>
  </Grid>
);

// FULL

export const FullRowGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} {...props}>
    {props.children}
  </Grid>
);

// HALF

export const HalfRowGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={6} {...props}>
    {props.children}
  </Grid>
);

// ONE THIRDS

export const OneThirdGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={4} {...props}>
    {props.children}
  </Grid>
);

// ONE FORTH

export const OneForthGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={3} {...props}>
    {props.children}
  </Grid>
);

// THREE FORTH

export const ThreeFourthsGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={9} {...props}>
    {props.children}
  </Grid>
);

// TWO THIRDS

export const TwoThirdsGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={8} {...props}>
    {props.children}
  </Grid>
);

// TEXT EDITOR

export const TextEditorGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={7} style={{ display: "flex", ...props.styles }} {...props}>
    {props.children}
  </Grid>
);

// INPUT

export const InputGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={2} {...props}>
    {props.children}
  </Grid>
);

// MEDIA ITEM

export const MediaItemGrid = (props: PropsWithChildren<any> & GridProps) => (
  <Grid item xs={12} md={3} {...props}>
    {props.children}
  </Grid>
);
