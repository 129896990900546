import { TypographyStyleOptions } from "@material-ui/core/styles/createTypography";

const typographyStyles: { [key: string]: TypographyStyleOptions } = {
  // material-ui names
  h1: {
    fontSize: 26,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.7)"
  },
  h2: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#C4C4C4"
  },
  h3: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#C4C4C4"
  },
  h4: {
    fontSize: 10,
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.60)"
  },
  subtitle1: {
    fontSize: 14,
    color: "#252733"
  },
  subtitle2: {
    fontSize: 12,
    color: "#C5C7CD"
  },
  body1: {
    fontSize: 16
  },
  body2: {
    fontSize: 14,
    color: "#4D4D4D"
  },
  button: {
    fontSize: 14,
    textTransform: "uppercase"
  },
  caption: {
    fontSize: 12
  },

  // custom names
  navLink: {
    fontSize: 16
  },
  subNavLink: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#1C1C1C"
  },
  binderLink: {
    fontSize: 19,
    fontWeight: "bold",
    textTransform: "uppercase"
  }
};

export default typographyStyles;
