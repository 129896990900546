import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// eslint-disable-next-line import/prefer-default-export
export const BASE_URL = "{{API_URL}}";

axios.defaults.baseURL = `${BASE_URL}/api`;
axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
