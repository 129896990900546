import React, { ReactNode } from "react";
import {
  CssBaseline as MuiCssBaseline,
  ThemeProvider as MuiThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme
} from "@material-ui/core";
import typographyStyles from "./Typography";

export const theme = createMuiTheme({
  typography: {
    fontFamily: ["Ubuntu", "Arial"].join(", "),
    fontWeightBold: 700,
    h1: typographyStyles.h1,
    h2: typographyStyles.h2,
    h3: typographyStyles.h3,
    h4: typographyStyles.h4,
    h5: {},
    h6: {},
    subtitle1: typographyStyles.subtitle1,
    subtitle2: typographyStyles.subtitle2,
    body1: typographyStyles.body1,
    body2: typographyStyles.body2,
    button: typographyStyles.button,
    caption: typographyStyles.caption,
    overline: {
      textTransform: "none",
      fontSize: 12
    }
  },
  palette: {
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.60)",
      disabled: "#C4C4C4",
      hint: "rgba(0, 0, 0, 0.38)"
    },
    grey: {
      "300": "#E5E5E5",
      "400": "#C4C4C4",
      "500": "#767676",
      "600": "#4D4D4D",
      "700": "rgba(0, 0, 0, 0.7)",
      "800": "#333333",
      "900": "#1C1C1C"
    },
    primary: {
      dark: "#153d6c",
      main: "#1e589b",
      light: "#4b79af"
    },
    secondary: {
      dark: "#6a1438",
      main: "#981d50",
      light: "#ac4a73"
    },
    background: {
      default: "#E3EBF3"
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
    // action: {}
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {}
    }
  }
});

const ThemeProvider = (props: { children: ReactNode }): JSX.Element => (
  <MuiThemeProvider theme={theme}>
    <MuiCssBaseline />
    {props.children}
  </MuiThemeProvider>
);

export default ThemeProvider;
