import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@material-ui/core";
import axios from "axios";
import { Permission, Role } from "./index";
import { getPermissions } from "../helpers";
import { handleDjangoApiError } from "../../helpers";
import StyledTextInput from "../../../Base/Inputs/TextInput";
import useAlert from "../../../Base/Dialog/Alert";

// TODO map role names => in the backend
// const mapRoles = {
//   viewUser: "Nutzer sehen",
//   editUser: "Nutzer bearbeiten",
//   viewRoles: "Rolen sehen",
//   editRoles: "Rolen bearbeiten",
//   viewTechnologies: "Technologien sehen",
//   editTechnologies: "Technologien bearbeiten",
//   viewExperimentRoom: "Experimentierräume sehen",
//   editExperimentRoom: "Experimentierräume bearbeiten"
// };

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    flexGrow: 1,
    paddingBottom: theme.spacing(3)
  },
  formGroupLabel: {
    paddingTop: theme.spacing(1)
  }
}));

interface RoleFormProps {
  role?: Role;
  setOpen?: (open: boolean) => void;
}

export default function RoleForm({ role, setOpen }: RoleFormProps): JSX.Element {
  const classes = useStyles();

  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    getPermissions().then((p) => {
      if (typeof p === "string") {
        console.warn(p);
      } else {
        setPermissions(p);
      }
    });
  }, []);

  const defaultName = role ? role.name : "";
  const defaultPermissions = role ? role.permissions.map((r) => r.id) : [];

  const successAlert = useAlert({ message: "Rolou!", severity: "success" });
  const apiErrorAlert = useAlert({ message: apiError || "", severity: "error" });

  return (
    <>
      <Formik<{ name: string; permissions: number[] }>
        initialValues={{
          name: defaultName,
          permissions: defaultPermissions
        }}
        validationSchema={Yup.object({
          name: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
          permissions: Yup.array().min(1, "Must choose at least one permission")
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          // console.log(values.permissions);
          try {
            if (role) {
              await axios.put(`roles/${role.id}`, {
                name: values.name,
                permissions: values.permissions
              });
            } else {
              await axios.post("roles", {
                name: values.name,
                permissions: values.permissions
              });
              resetForm();
            }
            successAlert.show();
            setApiError(undefined);
            if (setOpen) setOpen(false);
          } catch (e) {
            setApiError(handleDjangoApiError(e));
          }
        }}
      >
        {({ values, touched, errors, isSubmitting }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <StyledTextInput name="name" label="Name" />
              </Grid>
              <Grid item container>
                <FormControl error={touched.permissions && errors.permissions !== undefined} component="fieldset">
                  <FormLabel component="legend" className={classes.formGroupLabel}>
                    Permissions
                  </FormLabel>
                  <FormGroup>
                    <Grid container>
                      <FieldArray
                        name="permissions"
                        render={(arrayHelpers) => (
                          <>
                            {permissions.map((p) => (
                              <Grid key={p.id} item xs={12} sm={6}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      checked={values.permissions.includes(p.id)}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          arrayHelpers.push(p.id);
                                        } else {
                                          const idx = values.permissions.indexOf(p.id);
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                      value={p.id}
                                      name="permissions"
                                    />
                                  }
                                  label={p.name}
                                />
                              </Grid>
                            ))}
                          </>
                        )}
                      />
                      {touched.permissions && errors.permissions !== undefined && apiErrorAlert.show()}
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" fullWidth variant="contained" color="primary">
                  {/* TODO set time delay to show loading symbol */}
                  {isSubmitting ? "Loading..." : "Speichern"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {apiError && apiErrorAlert.show()}
      <apiErrorAlert.Component />
      <successAlert.Component />
    </>
  );
}
RoleForm.defaultProps = {
  role: undefined,
  setOpen: undefined
} as Partial<RoleFormProps>;
