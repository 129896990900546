import React, { useEffect } from "react";
import { Radar } from "ki-assist-radar";
import axios from "axios";
import RadarConfig from "./types";
import { handleDjangoApiError, toSnakeCase } from "../../helpers";

export interface RadarChartProps {
  config: RadarConfig;
  // data: Technology[];
}

export default function RadarChart({ config }: RadarChartProps): JSX.Element {
  useEffect(() => {
    // console.log("init");
    Radar.Init();
  }, []);

  useEffect(() => {
    // console.log("config");
    Radar.getById(`RADAR-${config.id}}`).setConfig(toSnakeCase(config));
  }, [config]);

  const getData = async (radarId: string) => {
    try {
      return await axios.get(`charts/public/radar/${radarId}`);
    } catch (e) {
      return handleDjangoApiError(e);
    }
  };

  useEffect(() => {
    // console.log("data");
    getData(config.id).then((resp) => {
      if (typeof resp === "string") {
        console.warn(resp);
      } else {
        const { data } = resp.data;
        Radar.getById(`RADAR-${config.id}}`).setData(data);
      }
    });
  }, [config.id]);

  return (
    <div className="radar" id={`RADAR-${config.id}}`} data-src="none">
      Fehler beim Laden des Radars
    </div>
  );
}
