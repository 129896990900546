import * as Yup from "yup";
import { isValidColor } from "../../../Base/Inputs/ColorInput";

export const easingOptions = [
  // source https://easings.net/
  "easeInOutSine",
  "easeInQuad",
  "easeOutQuad",
  "easeInOutQuad",
  "easeInCubic",
  "easeOutCubic",
  "easeInOutCubic",
  "easeInQuart",
  "easeOutQuart",
  "easeInOutQuart",
  "easeInQuint",
  "easeOutQuint",
  "easeInOutQuint",
  "easeInExpo",
  "easeOutExpo",
  "easeInOutExpo",
  "easeInCirc",
  "easeOutCirc",
  "easeInOutCirc",
  "easeInBack",
  "easeOutBack",
  "easeInOutBack",
  "easeInElastic",
  "easeOutElastic",
  "easeInOutElastic",
  "easeInBounce",
  "easeOutBounce",
  "easeInOutBounce"
];
type easingOptionsType = typeof easingOptions;

export default interface RadarConfig {
  id: string;
  title: string;
  description: string;
  width: number;
  height: number;
  maxres: number;

  showLegendBox: boolean;
  showViewsBox: boolean;

  showRadarEasing: easingOptionsType[number];
  highlightColor: string;
  linkColor: string;
  backgroundColor: string;

  viewsboxBorderColor: string;
  viewsboxBackgroundColor: string;
  viewsboxBackgroundColorHover: string;
  viewsboxTextColor: string;

  legendBorderColor: string;
  legendBackgroundColor: string;
  legendTextColor: string;

  searchboxBorderColor: string;
  searchboxBackgroundColor: string;
  searchboxTextColor: string;
  searchboxIconColor: string;

  sideboxBackgroundColor: string;
  sideboxBackgroundColorHover: string;
  sideboxH1Color: string;
  sideboxH2Color: string;
  radarItemsTextColor: string;

  radarLineColor: string;
  radarBackgroundColor: string;
  radarInnerTextColor: string;
  radarOuterTextColor: string;
  radarUnselectedItemsColor: string;
  radarFocusItemColor: string;
  radarTooltipBackgroundColor: string;
  descriptionTextColor: string;
  radarTooltipTextColor: string;
  radarShowTooltipShadow: false;
  radarTooltipShadowColor: string;
  radarTooltipShadowBlur: 25;
  radarTooltipShadowOffsetX: number;
  radarTooltipShadowOffsetY: number;

  gruppe1: string;
  gruppe2: string;
  gruppe3: string;
  gruppe4: string;
  gruppe5: string;
  gruppe6: string;
  gruppe7: string;
}

export const initialRadarValues: RadarConfig = {
  id: "",
  title: "",
  description: "",
  width: 1200,
  height: 640,
  maxres: 2048,

  showLegendBox: true,
  showViewsBox: true,

  showRadarEasing: easingOptions[9],
  highlightColor: "",
  linkColor: "",
  backgroundColor: "",

  viewsboxBorderColor: "",
  viewsboxBackgroundColor: "",
  viewsboxBackgroundColorHover: "",
  viewsboxTextColor: "",

  legendBorderColor: "",
  legendBackgroundColor: "",
  legendTextColor: "",

  searchboxBorderColor: "",
  searchboxBackgroundColor: "",
  searchboxTextColor: "",
  searchboxIconColor: "",

  sideboxBackgroundColor: "",
  sideboxBackgroundColorHover: "",
  sideboxH1Color: "",
  sideboxH2Color: "",
  radarItemsTextColor: "",

  radarLineColor: "",
  radarBackgroundColor: "",
  radarInnerTextColor: "",
  radarOuterTextColor: "",
  radarUnselectedItemsColor: "",
  radarFocusItemColor: "",
  radarTooltipBackgroundColor: "",
  descriptionTextColor: "",
  radarTooltipTextColor: "",
  radarShowTooltipShadow: false,
  radarTooltipShadowColor: "black",
  radarTooltipShadowBlur: 25,
  radarTooltipShadowOffsetX: 0,
  radarTooltipShadowOffsetY: 5,

  gruppe1: "",
  gruppe2: "",
  gruppe3: "",
  gruppe4: "",
  gruppe5: "",
  gruppe6: "",
  gruppe7: ""
};

export const isValidColorMessage =
  "Please inform a valid color. For more information about the available color names check www.w3schools.com/colors/colors_names.asp.";

export const radarValidationSchema = Yup.object({
  title: Yup.string().max(128, "Max 128").required("Required"),
  description: Yup.string().max(256, "Max 256").required("Required"),
  width: Yup.number().max(1600, "Max 1600").required("Required"),
  height: Yup.number().max(1200, "Max 1200").required("Required"),
  maxres: Yup.number().max(3000, "Max 3000").required("Required"),

  showLegendBox: Yup.boolean().required("Required"),
  showViewsBox: Yup.boolean().required("Required"),

  showRadarEasing: Yup.string().oneOf(easingOptions).required("Required"),

  highlightColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  linkColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  backgroundColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),

  radarLineColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  radarItemsTextColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  radarBackgroundColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  radarInnerTextColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  radarOuterTextColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  radarUnselectedItemsColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  radarFocusItemColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  radarTooltipBackgroundColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  descriptionTextColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  radarTooltipTextColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  radarShowTooltipShadow: Yup.boolean().required("Required"),
  radarTooltipShadowColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  radarTooltipShadowBlur: Yup.number().max(100, "Max 100").required("Required"),
  radarTooltipShadowOffsetX: Yup.number().max(100, "Max 100").required("Required"),
  radarTooltipShadowOffsetY: Yup.number().max(100, "Max 100").required("Required"),

  viewsboxBorderColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  viewsboxBackgroundColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  viewsboxBackgroundColorHover: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  viewsboxTextColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),

  legendBorderColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  legendBackgroundColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  legendTextColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),

  searchboxBorderColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  searchboxBackgroundColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  searchboxTextColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  searchboxIconColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),

  sideboxBackgroundColor: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  sideboxBackgroundColorHover: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  sideboxH1Color: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  sideboxH2Color: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),

  gruppe1: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  gruppe2: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  gruppe3: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  gruppe4: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  gruppe5: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  gruppe6: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required"),
  gruppe7: Yup.string()
    .max(25, "Max 25")
    .test("valid-color", isValidColorMessage, (v) => isValidColor(v || ""))
    .required("Required")
});

export const defaultColors = {
  highlightColor: "#004488",
  linkColor: "#4488aa",
  backgroundColor: "white",

  viewsboxBorderColor: "#999999",
  viewsboxBackgroundColor: "#eeeeee",
  viewsboxBackgroundColorHover: "#dddddd",
  viewsboxTextColor: "#333333",

  legendBorderColor: "#cccccc",
  legendBackgroundColor: "white",
  legendTextColor: "#333333",

  searchboxBorderColor: "#999999",
  searchboxBackgroundColor: "#eeeeee",
  searchboxTextColor: "#333333",
  searchboxIconColor: "#004488",

  sideboxBackgroundColor: "white",
  sideboxBackgroundColorHover: "#eeeeee",
  descriptionTextColor: "#777777",
  sideboxH1Color: "black",
  sideboxH2Color: "black",

  radarItemsTextColor: "white",
  radarLineColor: "#333333",
  radarBackgroundColor: "#f6f6f6",
  radarInnerTextColor: "#333333",
  radarOuterTextColor: "#333333",
  radarUnselectedItemsColor: "#333333",
  radarFocusItemColor: "#31a7ff",
  radarTooltipBackgroundColor: "#333333",
  radarTooltipTextColor: "white",

  gruppe1: "lightblue",
  gruppe2: "lightblue",
  gruppe3: "lightblue",
  gruppe4: "lightblue",
  gruppe5: "lightblue",
  gruppe6: "lightblue",
  gruppe7: "lightblue"
};

export const resetColors = {
  highlightColor: "",
  linkColor: "",
  backgroundColor: "",

  viewsboxBorderColor: "",
  viewsboxBackgroundColor: "",
  viewsboxBackgroundColorHover: "",
  viewsboxTextColor: "",

  legendBorderColor: "",
  legendBackgroundColor: "",
  legendTextColor: "",

  searchboxBorderColor: "",
  searchboxBackgroundColor: "",
  searchboxTextColor: "",
  searchboxIconColor: "",

  sideboxBackgroundColor: "",
  sideboxBackgroundColorHover: "",
  descriptionTextColor: "",
  sideboxH1Color: "",
  sideboxH2Color: "",

  radarItemsTextColor: "",
  radarLineColor: "",
  radarBackgroundColor: "",
  radarInnerTextColor: "",
  radarOuterTextColor: "",
  radarUnselectedItemsColor: "",
  radarFocusItemColor: "",
  radarTooltipBackgroundColor: "",
  radarTooltipTextColor: "",

  gruppe1: "",
  gruppe2: "",
  gruppe3: "",
  gruppe4: "",
  gruppe5: "",
  gruppe6: "",
  gruppe7: ""
};
