import React from "react";
import clsx from "clsx";
import { Paper as MuiPaper, PaperProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: "100%"
  }
}));

export default function StyledPaper(props: PaperProps): JSX.Element {
  const { children, className: otherClasses, ...p } = { ...props };
  const classes = useStyles();

  return (
    <MuiPaper className={clsx(otherClasses, classes.paper)} {...(p as PaperProps)}>
      {children}
    </MuiPaper>
  );
}
